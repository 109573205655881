import { apolloClient } from "../lib/apollo";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { gql } from "@apollo/client";

export default function SlugForm({ profile }) {
  const [edit, setEdit] = useState(false);
  const [slug, setSlug] = useState(profile?.slug ?? "");
  const [message, setMessage] = useState();

  const isValidSlug = useCallback(
    (input) => input != null && /^[a-z0-9]+(?:(-|\.)*[a-z0-9]+)*$/.test(input),
    []
  );

  useEffect(() => {
    if (!isValidSlug(slug)) {
      setMessage("띄어쓰기, 기호 또는 특수 문자는 사용할 수 없습니다.");
    } else {
      setMessage(undefined);
    }
  }, [slug, isValidSlug]);

  const cancelUpdate = () => {
    setSlug(profile.slug ?? "");
    setEdit(false);
  };
  const updateSlug = async () => {
    try {
      await apolloClient.mutate({
        mutation: UPDATE_PROFILE_SLUG_BY_PK,
        variables: { user_id: profile.user_id, profile: { slug: slug } },
      });
    } catch (e) {
      if (e.toString().indexOf("user_slug_key") >= 0) {
        setMessage(`${slug}는 이미 존재하는 url 입니다`);
      }
      return;
    }

    setEdit(false);
    fetch(`https://www.hithere.co/${slug}`);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div>www.hithere.co/</div>
        {edit ? (
          <>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={cancelUpdate}
                    onMouseDown={cancelUpdate}
                    edge="end"
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />

            <IconButton
              color="primary"
              onClick={updateSlug}
              style={{ fontSize: 14 }}
            >
              저장&nbsp;
              <CheckCircleOutlineIcon color="primary" />
            </IconButton>
          </>
        ) : (
          <div>
            {slug}
            <IconButton onClick={() => setEdit(true)}>
              <EditIcon />
            </IconButton>
            <a href={`https://www.hithere.co/${profile.slug}`}>
              호스트 상세 페이지 보기&nbsp;
              <LaunchIcon fontSize="6" />
            </a>
          </div>
        )}
        <span style={{ color: "red" }}>{message}</span>
        {/* <iframe
          title={profile.slug}
          src={`https://www.hithere.co/${profile.slug}`}
          width={0}
          height={0}
          style={{ borderWidth: 0 }}
        ></iframe> */}
      </div>
    </>
  );
}

const UPDATE_PROFILE_SLUG_BY_PK = gql`
  mutation UpdateProfileSlugByPk(
    $user_id: bigint!
    $profile: profile_set_input!
  ) {
    profile: update_profile_by_pk(
      pk_columns: { user_id: $user_id }
      _set: $profile
    ) {
      id
      user_id
      slug
    }
  }
`;
