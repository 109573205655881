import { Button, Stack } from "@mui/material";
import { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  List,
  ListButton,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useCreate,
  useDelete,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from "react-admin";
import AddHostDialog from "../components/tag/AddHostDialog";
import SelectHostForm from "../pages/HiGptPage/SelectHostForm";

const filters = [
  <TextInput label="태그이름" source="name" alwaysOn />,
  <CheckboxGroupInput
    source="type"
    label="타입"
    choices={[
      { id: "career", name: "커리어" },
      { id: "life", name: "라이프" },
    ]}
    alwaysOn
  />,
  <CheckboxGroupInput
    source="is_category"
    label="카테고리"
    choices={[{ id: "true", name: "true" }]}
    alwaysOn
  />,
  <CheckboxGroupInput
    source="is_hot"
    label="인기"
    choices={[{ id: "true", name: "true" }]}
    alwaysOn
  />,
  <CheckboxGroupInput
    source="is_banner"
    choices={[{ id: "true", name: "true" }]}
    alwaysOn
  />,
  <CheckboxGroupInput
    source="is_sub_banner"
    choices={[{ id: "true", name: "true" }]}
    alwaysOn
  />,
  <CheckboxGroupInput
    source="is_search_keyword"
    choices={[{ id: "true", name: "true" }]}
    alwaysOn
  />,
];

const ListActions = (props) => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const tagName = (s) =>
  s === "career" ? "커리어" : s === "life" ? "라이프" : null;

export const TagList = (props) => (
  <List filters={filters} actions={<ListActions />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="name" />
      <FunctionField label="타입" render={(record) => tagName(record.type)} />
      {/* <ReferenceManyField
        label="Hosts"
        reference="user_tag_view"
        target="tag_id"
      >
        <SingleFieldList style={{ maxWidth: "500px" }}>
          <FunctionField
            render={(record) => (
              <>
                <u>{record.profile_name}</u>&nbsp;&nbsp;
              </>
            )}
          />
        </SingleFieldList>
      </ReferenceManyField> */}
      <BooleanField source="is_category" label="카테고리" />
      <BooleanField source="is_hot" label="인기" />
      <BooleanField source="is_banner" />
      <BooleanField source="is_sub_banner" />
      <BooleanField source="is_search_keyword" />
      <DateField source="created_at" label="Created At" />
    </Datagrid>
  </List>
);

const TagTitle = () => {
  const record = useRecordContext();
  return <span>Tag: {record ? `${record.name}` : ""}</span>;
};

const TagShowActions = ({ data }) => {
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) return <></>;
  return (
    <TopToolbar>
      <AddHostDialog tag={record} onSuccess={() => refresh()} />
      <ListButton record={data} />
      <EditButton record={data} />
      <DeleteButton record={data} />
    </TopToolbar>
  );
};

export const TagShow = (props) => {
  const refresh = useRefresh();
  const [deleteOne] = useDelete();
  const redirect = useRedirect();
  const [selectedHost, setSelectedHost] = useState([]);
  const [createUserTag, { isLoading }] = useCreate();

  return (
    <Show title={<TagTitle />} actions={<TagShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="name" />
          <TextField source="description" />
          <FunctionField
            label="타입"
            render={(record) => tagName(record.type)}
          />
          <BooleanField source="is_category" label="카테고리" />
          <BooleanField source="is_hot" label="인기" />
          <BooleanField source="is_banner" />
          <BooleanField source="is_sub_banner" />
          <BooleanField source="is_search_keyword" />
          <FunctionField
            label="Hosts"
            render={(record) => {
              return (
                <Stack direction="row" alignItems="center">
                  <SelectHostForm
                    onSelect={(list) =>
                      setSelectedHost([...selectedHost, ...list])
                    }
                  />
                  {selectedHost.length > 0 && (
                    <span>
                      {selectedHost
                        .map((h) => `${h.name}(${h.user_id})`)
                        .join(",")}
                      <Button
                        onClick={async () => {
                          for (let h of selectedHost) {
                            await createUserTag(
                              "user_tag",
                              {
                                data: { tag_id: record.id, user_id: h.user_id },
                              },
                              { onSuccess: () => refresh() }
                            );
                          }

                          setSelectedHost([]);
                        }}
                      >
                        {selectedHost.length}명 호스트를 {record.name}에
                        추가하기
                      </Button>
                      <Button onClick={() => setSelectedHost([])}>
                        초기화
                      </Button>
                    </span>
                  )}
                </Stack>
              );
            }}
          />

          <ReferenceManyField
            label="Hosts"
            reference="user_tag_view"
            target="tag_id"
          >
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                label="이름"
                render={(record) => (
                  <button
                    onClick={() => redirect(`/profile/${record.user_id}/show`)}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      boxShadow: "none",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    {record.profile_name}
                  </button>
                )}
              />
              <TextField source="profile_title" label="제목" />
              <TextField source="profile_rank" label="rank" />
              <FunctionField
                label="삭제"
                render={(record) => (
                  <button
                    onClick={() =>
                      deleteOne(
                        "user_tag",
                        { id: record.id },
                        { onSuccess: () => refresh() }
                      )
                    }
                  >
                    삭제
                  </button>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const TagCreate = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [create] = useCreate();
  return (
    <Create {...props}>
      <SimpleForm
        onSubmit={(values) => {
          if (values.type === "") {
            delete values.type;
          }
          create(
            "tag",
            { data: values },
            {
              onSuccess: (data) => {
                refresh();
                redirect(`/tag/${data.id}/show`);
              },
            }
          );
        }}
      >
        <TextInput source="name" />
        <TextInput source="description" />
        <BooleanInput source="is_category" label="카테고리" />
        <BooleanInput source="is_hot" label="인기" />
        <BooleanInput source="is_banner" />
        <BooleanInput source="is_sub_banner" />
        <BooleanInput source="is_search_keyword" />
        <SelectInput
          source="type"
          label="타입"
          allowEmpty
          choices={[
            { id: "career", name: "커리어" },
            { id: "life", name: "라이프" },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export const TagEdit = (props) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [update] = useUpdate();
  return (
    <Edit {...props} mutationMode="optimistic">
      <SimpleForm
        onSubmit={(values) => {
          if (values.type === "") {
            delete values.type;
          }
          update(
            "tag",
            { id: values.id, data: values },
            {
              onSuccess: (data) => {
                refresh();
                redirect(`/tag/${data.id}/show`);
              },
            }
          );
        }}
      >
        <TextInput disabled label="Id" source="id" />
        <TextInput source="name" />
        <TextInput source="description" />
        <BooleanInput source="is_category" label="카테고리" />
        <BooleanInput source="is_hot" label="인기" />
        <BooleanInput source="is_banner" />
        <BooleanInput source="is_sub_banner" />
        <BooleanInput source="is_search_keyword" />
        <SelectInput
          source="type"
          label="타입"
          allowEmpty
          choices={[
            { id: "career", name: "커리어" },
            { id: "life", name: "라이프" },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};
