import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiAction } from "../../apiAction";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

export default function History() {
  const [items, setItems] = useState([]);
  const [name, setName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    getHistory();
  }, []);

  async function getHistory() {
    const { data } = await apiAction("/gpt/history", {});
    data.sort((a, b) => (dayjs(a.request_at).isBefore(b.request_at) ? 1 : -1));
    setItems(data);
  }
  return (
    <>
      <Button
        variant="contained"
        onClick={getHistory}
        style={{ margin: 10, float: "right" }}
      >
        새로고침
      </Button>
      <h2 sx={{ mr: 10 }}>결과 필터링</h2>
      <Stack flexDirection="row" alignItems="center" sx={{ mb: 2 }}>
        호스트 이름:{" "}
        <TextField
          sx={{ ml: 1, mr: 5, width: 100 }}
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        질문:{" "}
        <TextField
          sx={{ ml: 1, mr: 5, width: 250 }}
          size="small"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        대답:{" "}
        <TextField
          sx={{ ml: 1, mr: 5, width: 300 }}
          size="small"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        {/* <Button variant="contained" onClick={getHistory}>
          조회
        </Button> */}
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={70}>
                대상
              </TableCell>
              <TableCell align="center">질문</TableCell>
              <TableCell align="center">대답</TableCell>
              <TableCell align="center">시간</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter((t) => {
                if (name && !t.target.includes(name)) {
                  return false;
                }
                if (prompt && !t.prompt.includes(prompt)) {
                  return false;
                }
                if (content && !t.choice_message_content.includes(content)) {
                  return false;
                }
                return true;
              })
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <div style={{ width: 100 }}>{row.target}</div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div
                      style={{ width: 500 }}
                      dangerouslySetInnerHTML={{
                        __html:
                          row.prompt?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
                      }}
                    ></div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{ width: 500 }}
                      dangerouslySetInnerHTML={{
                        __html:
                          row.choice_message_content?.replace(
                            /(?:\r\n|\r|\n)/g,
                            "<br>"
                          ) ?? "",
                      }}
                    ></div>
                  </TableCell>
                  <TableCell>
                    <div style={{ width: 130 }}>
                      {dayjs(row.request_at).format("MM.DD A hh:mm:ss")}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
