import dayjs from "dayjs";
import { useGetList } from "react-admin";

export default function MeetingCount({ order, detailed = false }) {
  const { data } = useGetList("meeting", {
    filter: {
      order_id: order?.id,
      status: "reserved",
    },
  });
  if (!data) return <></>;

  const pasts = (data ?? []).filter((m) => dayjs(m.end_at).isBefore(dayjs()));
  const upcomings = (data ?? []).filter((m) =>
    dayjs(m.end_at).isAfter(dayjs())
  );

  if (!order) return <></>;

  if (!detailed) {
    return (
      <center>
        {pasts.length}/{order.meeting_count}
      </center>
    );
  }
  return (
    <ul>
      <li>총횟수: {order.meeting_count}</li>
      <li>
        지난 대화:
        <ul>
          {pasts.map((m) => (
            <li key={m.id}>{dayjs(m.start_at).format("YYYY.MM.DD A hh:mm")}</li>
          ))}
        </ul>
      </li>
      {upcomings.length > 0 && (
        <li>
          예정된 대화:
          <ul>
            {upcomings.map((m) => (
              <li key={m.id}>
                {dayjs(m.start_at).format("YYYY.MM.DD A hh:mm")}
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
}
