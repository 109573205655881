import { Box, Button, IconButton, TextField } from "@mui/material";
import { useGetList } from "react-admin";
import { useForm, useFieldArray } from "react-hook-form";
import { styled } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { gql } from "@apollo/client";
import { apolloClient } from "../../lib/apollo";

function Form({ experiences, user_id }) {
  const {
    getValues,
    control,
    register,
    formState: { isDirty },
  } = useForm({ defaultValues: { experiences: experiences } });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experiences",
  });

  const addItem = () => append({ user_id: user_id, title: "" });
  const removeItem = (i) => remove(i);

  const onSubmit = async () => {
    const values = getValues().experiences;
    for (let i = 0; i < values.length; i++) {
      values[i].seq = i + 1;
    }

    const added = values.filter((p) => !Number.isInteger(p.id));
    const deleted = experiences.filter(
      (v) => values.findIndex((p) => p.id === v.id) < 0
    );
    const edited = values
      .filter((p) => {
        if (!p.id) return false;
        const initValue = experiences.find((v) => v.id === p.id);
        console.log(initValue, p);
        if (!initValue) return false;
        if (
          initValue.seq === p.seq &&
          initValue.category === p.category &&
          initValue.title === p.title &&
          initValue.desc === p.desc &&
          initValue.start === p.start &&
          initValue.end === p.end
        )
          return false;
        return true;
      })
      .map((p) => ({
        id: p.id,
        user_id: p.user_id,
        title: p.title,
        desc: p.desc,
        start: p.start,
        end: p.end,
        category: p.category,
        seq: p.seq,
      }));

    if (added.length + deleted.length + edited.length > 0) {
      await apolloClient.mutate({
        mutation: UPDATE_EXPERIENCE_LIST,
        variables: {
          deleted_ids: deleted.map((p) => p.id),
          inserted_experiences: [...added, ...edited],
        },
      });
    }
  };

  return (
    <Box sx={{ borderStyle: "groove", p: "20px" }}>
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <StyledTextField
              id="filled-size-normal"
              variant="outlined"
              label="카테고리"
              placeholder="'미디어' 또는 '기타'"
              defaultValue={p.category}
              {...register(`experiences.${i}.category`)}
            />
            <StyledTextField
              id="filled-size-normal"
              variant="outlined"
              label="시작"
              defaultValue={p.start}
              {...register(`experiences.${i}.start`)}
            />
            <StyledTextField
              id="filled-size-normal"
              variant="outlined"
              label="종료"
              defaultValue={p.end}
              {...register(`experiences.${i}.end`)}
            />
            <br />
            <StyledTextField
              id="filled-size-normal"
              variant="outlined"
              label="내용"
              sx={{ width: "90%" }}
              defaultValue={p.title}
              {...register(`experiences.${i}.title`)}
            />
            <StyledTextField
              id="filled-size-normal"
              variant="outlined"
              label="세부내용"
              sx={{ width: "90%" }}
              defaultValue={p.desc}
              {...register(`experiences.${i}.desc`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
      <Button
        variant="contained"
        color="primary"
        endIcon={<SaveIcon />}
        sx={{ width: 180 }}
        disabled={!isDirty}
        onClick={onSubmit}
      >
        저장
      </Button>
    </Box>
  );
}
export default function ExperienceEdit({ profile }) {
  const { data } = useGetList("experience", {
    filter: {
      user_id: profile?.user_id,
    },
  });
  if (!data) return <></>;
  return (
    <Form
      experiences={data.sort((a, b) => (a.seq < b.seq ? -1 : 1))}
      user_id={profile.user_id}
    />
  );
}

const UPDATE_EXPERIENCE_LIST = gql`
  mutation BulkUpdageExperienceList(
    $deleted_ids: [bigint!]!
    $inserted_experiences: [experience_insert_input!]!
  ) {
    delete_experience(where: { id: { _in: $deleted_ids } }) {
      returning {
        id
      }
    }
    insert_experience(
      objects: $inserted_experiences
      on_conflict: {
        constraint: experience_pkey
        update_columns: [title, desc, start, end, category, seq]
      }
    ) {
      returning {
        id
        user_id
        title
        desc
        start
        end
        category
        seq
        created_at
        updated_at
      }
    }
  }
`;

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0.5rem 0.5rem 0;
`;
