import { gql } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Confirm, Button as RaButton, useGetOne } from "react-admin";
import { apolloClient } from "../../lib/apollo";

function DeleteUser({ userId, onSuccess }) {
  const [email, setEmail] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useGetOne("user", { id: userId });

  return (
    <>
      <DialogTitle>사용자 삭제</DialogTitle>
      <DialogContent>
        <DialogContentText>
          삭제하려고 하는 사람의 이메일을 입력하세요. (재확인)
        </DialogContentText>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: "300px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpenConfirm(true)}
        >
          삭제
        </Button>
      </DialogActions>
      <Confirm
        isOpen={openConfirm}
        loading={isLoading}
        title="사용자 삭제"
        content="정말 삭제할까요? 취소 못함!!"
        onConfirm={async () => {
          if (data.email !== email && data.email2 !== email) {
            alert("이메일이 일치하지 않습니다.");
            setOpenConfirm(false);
            return;
          }
          setIsLoading(true);
          const resp = await apolloClient.mutate({
            mutation: DELETE_USER,
            variables: {
              user_id: userId,
            },
          });
          console.log(resp.data);
          setIsLoading(false);
          alert("삭제되었습니다.");
          setOpenConfirm(false);
          onSuccess();
        }}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  );
}

export default function DeleteUserDialog({ userId, onSuccess }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="사용자 삭제" />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DeleteUser
          userId={userId}
          onSuccess={() => {
            if (onSuccess) onSuccess();
            setOpen(false);
          }}
        />
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Dialog>
    </>
  );
}

export const DELETE_USER = gql`
  mutation DeleteUser($user_id: bigint!) {
    delete_experience(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_point(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_user_tag(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_timetable(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_like(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_interest(where: { user_id: { _eq: $user_id } }) {
      affected_rows
    }
    delete_profile_by_pk(user_id: $user_id) {
      id
    }
    update_user_by_pk(
      pk_columns: { id: $user_id }
      _set: {
        timezone: null
        agreement_account_activation: false
        agreement_adult: false
        agreement_email: false
        agreement_guest_guideline: false
        agreement_privacy: false
        agreement_sms: false
        agreement_terms: false
        bank_account: null
        birth_year: null
        company: null
        device_token: []
        disabled: true
        from_where: null
        gender: null
        is_kakao_plus_friend: false
        kakao_user_id: null
        kakao_userinfo: null
        location: null
        mobile: null
        position: null
        role: "guest"
        signup_code: null
        team: ""
        utm_campaign: null
        utm_source: null
        zoom_access_token: null
        zoom_email: null
        zoom_expires_at: null
        zoom_user_id: null
        zoom_status: null
        zoom_refresh_token: null
      }
    ) {
      id
    }
  }
`;
