import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  FunctionField,
  NumberField,
  TextInput,
  SingleFieldList,
  ReferenceField,
  ReferenceManyField,
} from "react-admin";
import { priceStr, trim } from "../util";
import { Link } from "react-router-dom";
import ClearanceDialog from "../components/order/ClearanceDialog";
const filters = [
  <TextInput label="호스트 이름으로 검색" source="host_name" alwaysOn />,
];

export const SaleList = (props) => {
  return (
    <List filters={filters} {...props}>
      <Datagrid bulkActionButtons={false} style={{ width: "1658px" }}>
        <FunctionField
          label="id"
          render={(record) => (
            <Link to={`/sale_record/${record.id}/show`}>{record.id}</Link>
          )}
        />
        <DateField source="order_completed_at" label="완료날짜" />
        <FunctionField
          label="게스트"
          render={(record) => trim(record.guest_name, 8)}
        />
        {/* <FunctionField
          label="소속"
          render={(record) => (
            <div style={{ minWidth: "90px", maxWidth: "75px" }}>
              <ReferenceManyField
                reference="organization_user"
                source="guest_id"
                target="user_id"
              >
                <SingleFieldList>
                  <ReferenceField
                    source="organization_id"
                    reference="organization"
                    link="show"
                  >
                    <TextField label="이름" source="name" />
                  </ReferenceField>
                </SingleFieldList>
              </ReferenceManyField>
            </div>
          )}
        /> */}
        <ReferenceField
          label="호스트"
          source="host_id"
          reference="profile"
          link="show"
        >
          <TextField label="이름" source="name" />
        </ReferenceField>
        {/* <FunctionField
          label="제목"
          render={(record) => trim(record.title, 10)}
        /> */}
        <NumberField source="total_amount" label="가격" />
        <NumberField source="pay_price" label="판매 가격" />
        {/* <NumberField source="base_price" label="기본 가격" />
        <NumberField
          source="host_fee_rate"
          label={
            <>
              호스트
              <br />
              수수료
            </>
          }
        /> */}
        <NumberField source="host_base_price" label="호스트 수익" />
        <NumberField source="host_tax_1_amount" label="소득세" />
        <NumberField source="host_tax_2_amount" label="지방소득세" />
        <NumberField
          source="host_settlement_amount"
          label={
            <>
              호스트 예상
              <br />
              지급 금액
            </>
          }
        />
        <FunctionField
          render={(record) => <ClearanceDialog saleRecord={record} />}
        />
        <NumberField
          source="real_host_settlement_amount"
          label={
            <>
              호스트 실제
              <br />
              지급 금액
            </>
          }
        />
        <NumberField
          source="real_host_tax_1_amount"
          label={
            <>
              실제
              <br />
              소득세
            </>
          }
        />
        <NumberField
          source="real_host_tax_2_amount"
          label={
            <>
              실제
              <br />
              지방소득세
            </>
          }
        />
        <DateField source="settlement_date" label="정산날짜" />
        <FunctionField
          label="note"
          render={(record) => (
            <div style={{ width: "115px" }}>{record.note}</div>
          )}
        />
        <DateField source="mail_sent_at" label="메일발송" />
      </Datagrid>
    </List>
  );
};

const SaleTitle = ({ record }) => {
  return (
    <span>
      Sale:{" "}
      {record ? `[${record.user_display_name}] ${record.description}` : ""}
    </span>
  );
};

export const SaleShow = (props) => {
  return (
    <Show title={<SaleTitle />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <DateField source="order_created_at" label="결제날짜" />
          <DateField source="order_completed_at" label="완료날짜" />
          <TextField source="guest_name" label="게스트" />
          <FunctionField
            label="소속"
            render={(record) => (
              <div style={{ minWidth: "90px", maxWidth: "75px" }}>
                <ReferenceManyField
                  reference="organization_user"
                  source="guest_id"
                  target="user_id"
                >
                  <SingleFieldList>
                    <ReferenceField
                      source="organization_id"
                      reference="organization"
                      link="show"
                    >
                      <TextField label="이름" source="name" />
                    </ReferenceField>
                  </SingleFieldList>
                </ReferenceManyField>
              </div>
            )}
          />
          <TextField source="host_name" label="호스트" />
          <TextField source="title" label="제목" />
          <NumberField source="total_amount" label="가격" />
          <NumberField source="pay_price" label="판매 가격" />
          <FunctionField
            label="결제 금액"
            render={(record) =>
              priceStr(record.pay_amount, record.pay_currency)
            }
          />
          <TextField source="pay_method" label="지불 수단" />
          <NumberField source="base_price" label="기본 가격" />
          <NumberField source="host_fee_rate" label="호스트 수수료" />
          <NumberField source="host_base_price" label="호스트 수익" />
          <NumberField source="host_tax_1_amount" label="소득세" />
          <NumberField source="host_tax_2_amount" label="지방소득세" />
          <NumberField
            source="host_settlement_amount"
            label="호스트 예상 지급 금액"
          />
          <NumberField
            source="real_host_settlement_amount"
            label="호스트 실제 지급 금액"
          />
          <NumberField source="real_host_tax_1_amount" label="실제 소득세" />
          <NumberField
            source="real_host_tax_2_amount"
            label="실제 지방소득세"
          />
          <DateField source="settlement_date" label="정산날짜" />
          <TextField source="note" />
          <DateField source="mail_sent_at" label="메일발송" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
