import {
  List,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  Edit,
  SimpleForm,
  EditButton,
  FunctionField,
  ReferenceManyField,
  useRefresh,
  useDelete,
  useRecordContext,
  ReferenceField,
} from "react-admin";
import AddHostDialog from "../components/organization/AddHostDialog";
import { Stack } from "@mui/material";
import { isAdmin } from "../lib/auth";

export const OrganizationList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="이름" />
      <TextField source="slug" />
      <DateField source="created_at" label="생성날짜" />
    </Datagrid>
  </List>
);

const OrganizationTitle = ({ record }) => {
  return <span>Organization: {record ? `${record.name}` : ""}</span>;
};

const OrganizationShowActions = ({ data }) => {
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) return <></>;
  if (!isAdmin()) return <></>;
  return (
    <TopToolbar>
      <AddHostDialog organization={record} refresh={refresh} />
      <ListButton record={data} />
      <EditButton record={data} />
    </TopToolbar>
  );
};

export const OrganizationShow = (props) => {
  const refresh = useRefresh();
  const [deleteOne] = useDelete();
  return (
    <Show
      title={<OrganizationTitle />}
      actions={<OrganizationShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="name" label="이름" />
          <TextField source="slug" />
          <DateField source="created_at" label="생성날짜" />
        </Tab>
        <Tab label="user">
          <ReferenceManyField
            label="Users"
            reference="organization_user"
            target="organization_id"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label="사용자"
                source="user_id"
                reference="user"
                link="show"
              >
                <Stack direction="row">
                  <TextField
                    label="이름"
                    source="display_name"
                    width={"80px"}
                  />
                  <TextField source="role" width={"50px"} />
                  <TextField label="이메일" source="email" width={"220px"} />
                  <TextField label="전화번호" source="mobile" />
                </Stack>
              </ReferenceField>
              {/* <FunctionField
                label="삭제"
                render={(record) => (
                  <button
                    onClick={() =>
                      deleteOne(
                        "organization_user",
                        { id: record.id },
                        { onSuccess: () => refresh() }
                      )
                    }
                  >
                    삭제
                  </button>
                )}
              /> */}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="host">
          <ReferenceManyField
            label="Hosts"
            reference="organization_host"
            target="organization_id"
          >
            <Datagrid bulkActionButtons={false}>
              <ReferenceField
                label="호스트"
                source="host_id"
                reference="profile"
                link="show"
              >
                <Stack direction="row">
                  <TextField label="이름" source="name" width={"80px"} />
                  <TextField label="제목" source="title" />
                </Stack>
              </ReferenceField>
              <FunctionField
                label="삭제"
                render={(record) => (
                  <button
                    onClick={() =>
                      deleteOne(
                        "organization_host",
                        { id: record.id },
                        { onSuccess: () => refresh() }
                      )
                    }
                  >
                    삭제
                  </button>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const OrganizationEdit = (props) => (
  <Edit {...props} redirect="show">
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" label="이름" />
      <TextInput source="slug" />
      <DateField source="created_at" label="생성날짜" />
    </SimpleForm>
  </Edit>
);
