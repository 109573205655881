import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  Link,
} from "react-admin";
import dayjs from "dayjs";

export const MessageList = (props) => (
  <List {...props} style={{ width: "1100px" }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField
        label="작성자"
        source="user_id"
        reference="user"
        link="show"
      >
        <TextField label="이름" source="display_name" />
      </ReferenceField>
      <TextField label="type" source="content_type" />
      <FunctionField
        label="메세지 내용"
        render={(record) => (
          <div style={{ width: "600px" }}>
            <Link to={`/thread_view/${record.thread_id}/show`}>
              {record.content_type !== "event" ? (
                record.content.body
              ) : (
                <>
                  {record.content.body} <br />
                  <small>{record.content.info.title}</small>
                </>
              )}
              {record.content_type === "reschedule" && (
                <small>
                  {dayjs(record.content.info?.start_at).format(
                    " (제안시간: M/D HH:ss)"
                  )}
                </small>
              )}
            </Link>
          </div>
        )}
      />
      <DateField label="작성시간" source="created_at" showTime />
    </Datagrid>
  </List>
);
