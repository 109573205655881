import { Box, Button, IconButton, TextField } from "@mui/material";
import { useGetList } from "react-admin";
import { useForm, useFieldArray } from "react-hook-form";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { gql } from "@apollo/client";
import { apolloClient } from "../../lib/apollo";

function Form({ points, user_id }) {
  const {
    getValues,
    control,
    register,
    formState: { isDirty },
  } = useForm({ defaultValues: { points: points } });

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "points",
  });

  const addItem = () => append({ user_id: user_id, title: "" });
  const removeItem = (i) => remove(i);

  const onSubmit = async () => {
    const values = getValues().points;
    for (let i = 0; i < values.length; i++) {
      values[i].seq = i + 1;
    }

    const added = values.filter((p) => !Number.isInteger(p.id));
    const deleted = points.filter(
      (v) => values.findIndex((p) => p.id === v.id) < 0
    );
    const edited = values
      .filter((p) => {
        if (!p.id) return false;
        const initValue = points.find((v) => v.id === p.id);
        console.log(initValue, p);
        if (!initValue) return false;
        if (
          initValue.seq === p.seq &&
          initValue.title === p.title &&
          initValue.type === p.type
        )
          return false;
        return true;
      })
      .map((p) => ({
        id: p.id,
        user_id: p.user_id,
        type: p.type,
        title: p.title,
        seq: p.seq,
      }));

    if (added.length + deleted.length + edited.length > 0) {
      await apolloClient.mutate({
        mutation: UPDATE_POINT_LIST,
        variables: {
          deleted_ids: deleted.map((p) => p.id),
          inserted_points: [...added, ...edited],
        },
      });
    }
  };

  return (
    <Box sx={{ borderStyle: "groove", p: "20px" }}>
      나눌 수 있는 이야기:
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <IconButton
              aria-label="up"
              disabled={i === 0}
              onClick={() => swap(i, i - 1)}
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              aria-label="down"
              disabled={i === fields.length - 1}
              onClick={() => swap(i, i + 1)}
              style={{ marginRight: "0.5rem" }}
            >
              <ArrowDownwardIcon />
            </IconButton>

            <label htmlFor="career">
              <input
                {...register(`points.${i}.type`)}
                type="radio"
                value="career"
                id="career"
              />
              커리어
            </label>
            <label htmlFor="life">
              <input
                {...register(`points.${i}.type`)}
                type="radio"
                value="life"
                id="life"
              />
              라이프
            </label>
            <br />
            <TextField
              key={p.id}
              id="filled-size-normal"
              placeholder="ex) 실리콘 밸리에서 취업하기."
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              defaultValue={p.title}
              {...register(`points.${i}.title`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
      <Button
        variant="contained"
        color="primary"
        endIcon={<SaveIcon />}
        sx={{ width: 180 }}
        disabled={!isDirty}
        onClick={onSubmit}
      >
        저장
      </Button>
    </Box>
  );
}
export default function PointEdit({ profile }) {
  const { data } = useGetList("point", {
    filter: {
      user_id: profile?.user_id,
    },
  });
  if (!data) return <></>;
  return (
    <Form
      points={data.sort((a, b) => (a.seq < b.seq ? -1 : 1))}
      user_id={profile.user_id}
    />
  );
}

const UPDATE_POINT_LIST = gql`
  mutation BulkUpdagePointList(
    $deleted_ids: [bigint!]!
    $inserted_points: [point_insert_input!]!
  ) {
    delete_point(where: { id: { _in: $deleted_ids } }) {
      returning {
        id
      }
    }
    insert_point(
      objects: $inserted_points
      on_conflict: {
        constraint: point_pkey
        update_columns: [title, seq, type]
      }
    ) {
      returning {
        id
        user_id
        title
        seq
        type
        created_at
        updated_at
      }
    }
  }
`;
