import Query from "./Query";
import History from "./History";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";

export default function Index() {
  const [tabIndex, setTabIndex] = useState("2");
  return (
    <>
      <h1>HiGPT</h1>

      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(_, v) => setTabIndex(v)}>
            <Tab label="이전에 했던 질문들" value="2" />
            <Tab label="아무거나 질문하기" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Query />
        </TabPanel>
        <TabPanel value="2">
          <History />
        </TabPanel>
      </TabContext>
    </>
  );
}
