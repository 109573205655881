import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchProfile from "../../components/profile/SearchProfile";

export default function SelectHostForm({ onSelect }) {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [hostName, setHostName] = useState("");
  const [selectedHost, setSelectedHost] = useState([]);

  useEffect(() => {
    if (onSelect && selectedHost.length > 0) {
      onSelect(selectedHost);
      setSelectedHost([]);
    }
  }, [onSelect, selectedHost]);

  return (
    <>
      <Button onClick={() => setOpen(true)}>호스트 선택</Button>
      {/* {selectedHost.length === 0 && (
        <small style={{ color: "grey" }}>
          1명 이상의 호스트를 선택해주세요
        </small>
      )} */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <IconButton
            aria-label="toggle password visibility"
            sx={{ float: "right" }}
            onClick={() => setOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack flexDirection="row" mt={1}>
            <TextField
              label="호스트 이름"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setHostName(searchText);
                }
              }}
            />
            <Button onClick={() => setHostName(searchText)}>조회</Button>
          </Stack>
          {hostName.length > 0 && (
            <SearchProfile
              name={hostName}
              onSelect={(h) =>
                setSelectedHost(
                  Object.values(
                    [...selectedHost, h].reduce(
                      (a, c) => Object.assign(a, { [c.id]: c }),
                      {}
                    )
                  )
                )
              }
            />
          )}
          <DialogActions>
            <Button onClick={() => setOpen(false)}>닫기</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
