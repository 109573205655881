import { gql } from "@apollo/client";
import { useState } from "react";
import {
  BooleanField,
  Button,
  CheckboxGroupInput,
  ChipField,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { CSVLink } from "react-csv";
import { apiAction } from "../apiAction";
import SendMessageDialog from "../components/message/SendMessageDialog";
import DeleteUserDialog from "../components/user/DeleteUserDialog";
import { apolloClient } from "../lib/apollo";
import { isAdmin } from "../lib/auth";

const filters = [
  <TextInput label="이름으로 검색" source="display_name" alwaysOn />,
  <TextInput label="이메일로 검색" source="email" alwaysOn />,
  <CheckboxGroupInput
    source="role"
    choices={[{ id: "host", name: "호스트" }]}
    alwaysOn
  />,
];

const UserListActions = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState(undefined);
  return (
    <TopToolbar>
      {csvData && (
        <CSVLink filename={"users.csv"} data={csvData}>
          CSV 파일 다운로드
        </CSVLink>
      )}
      {!csvData && (
        <Button
          label={loading ? "변환중..." : "사용자 이메일 주소 내려받기"}
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            const { data } = await apolloClient.query({
              query: QUERY_USER_EMAILS,
            });
            const emails = data.user.map((u) => {
              if (u.email === u.email2) {
                return [u.email, u.display_name];
              }
              if (!u.email2) {
                return [u.email, u.display_name];
              }
              return [u.email2, u.display_name];
            });

            setCsvData([["이메일", "이름"], ...emails]);
            setLoading(false);
          }}
        />
      )}
    </TopToolbar>
  );
};

export const UserList = (props) => (
  <List filters={filters} actions={<UserListActions />} {...props}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      style={{ width: "1500px" }}
    >
      <TextField source="id" />
      <FunctionField
        label="이름"
        render={(record) => (
          <div style={{ maxWidth: "100px", wordWrap: "break-word" }}>
            {record.role === "host" ? (
              <ReferenceField
                label="호스트"
                source="id"
                reference="profile"
                link="show"
              >
                <TextField label="이름" source="name" />
              </ReferenceField>
            ) : (
              record.display_name
            )}
          </div>
        )}
      />
      <FunctionField
        label="이메일"
        render={(record) => {
          if (record.email === record.email2) return record.email;
          if (!record.email2) return record.email;
          return (
            <>
              {record.email}
              <br />
              {record.email2}
            </>
          );
        }}
      />
      <FunctionField
        label="소속"
        render={(record) => (
          <div style={{ minWidth: "90px", maxWidth: "75px" }}>
            <ReferenceManyField reference="organization_user" target="user_id">
              <SingleFieldList>
                <ReferenceField
                  source="organization_id"
                  reference="organization"
                  link="show"
                >
                  <TextField label="이름" source="name" />
                </ReferenceField>
              </SingleFieldList>
            </ReferenceManyField>
          </div>
        )}
      />
      <FunctionField
        label="role"
        render={(record) => {
          if (record.disabled) {
            return (
              <span style={{ color: "red", fontWeight: 900 }}>비활성화</span>
            );
          }
          if (record.role === "host") {
            return <ChipField source="role" />;
          }
        }}
      />
      <TextField source="timezone" />
      <FunctionField
        label="utm"
        render={(record) => (
          <>
            {record.utm_source}
            <br />
            {record.utm_campaign}
          </>
        )}
      />
      <FunctionField
        label="provider"
        render={(record) => {
          if (record.kakao_user_id) {
            if (record.is_kakao_plus_friend === true) return "kakao(채널추가)";
            else return "kakao";
          }
          if ((record.provider_data ?? []).length === 0) return null;

          return record.provider_data[0].providerId;
        }}
      />
      <BooleanField source="agreement_sms" label="핸드폰인증" />
      {/* <FunctionField
        label="이벤트 수신 알림 동의"
        render={(record) => {
          if (record.agreement_email)
            return <BooleanField source="agreement_terms" />;
        }}
      /> */}
      <FunctionField
        label="가입동의"
        render={(record) => {
          if (
            record.agreement_terms &&
            record.agreement_privacy &&
            record.agreement_adult
          )
            return <BooleanField source="agreement_terms" />;
        }}
      />
      {/* <BooleanField label="이벤트 수신 알림 동의" source="agreement_email" />
      <BooleanField
        label="계정이 활성 상태 유지 동의"
        source="agreement_account_activation"
      />
      <BooleanField
        label="게스트 가이드라인 동의"
        source="agreement_guest_guideline"
      /> */}
      <DateField source="created_at" label="Created At" showTime />
    </Datagrid>
  </List>
);

const UserTitle = () => {
  const record = useRecordContext();
  return <span>User: {record?.display_name}</span>;
};

const UserShowActions = () => {
  const record = useRecordContext();
  const updateKakaoChannelStatus = async () => {
    const { success, error } = await apiAction("/kakao/channel/update", {
      userId: record.id,
    });
    if (success === true) alert("성공");
    else alert(`실패 - ${error}`);
  };
  if (!isAdmin()) return <></>;
  return (
    <TopToolbar>
      {record.disabled && (
        <div
          style={{ height: 24, marginRight: 20, color: "red", fontWeight: 900 }}
        >
          이 사용자는 비활성화되었습니다
        </div>
      )}
      {record?.kakao_user_id && (
        <Button
          onClick={updateKakaoChannelStatus}
          label="카카오 채널 상태 업데이트"
        />
      )}
      <DeleteUserDialog userId={record?.id} />
      <SendMessageDialog guestUserId={record?.id} />
      <EditButton />
      <ListButton label="Back" />
    </TopToolbar>
  );
};

export const UserShow = (props) => (
  <Show title={<UserTitle />} actions={<UserShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <FunctionField
          label="이름"
          render={(record) => {
            if (record.role !== "host") return record.display_name;
            return (
              <>
                <span>{record.display_name}</span> (
                <ReferenceField source="id" reference="profile" link="show">
                  <TextField source="name" /> 호스트
                </ReferenceField>
                )
              </>
            );
          }}
        />
        <TextField source="email" label="이메일" />
        <TextField source="email2" label="자주 사용하는 이메일" />
        <FunctionField
          label="소속"
          render={(record) => (
            <div style={{ minWidth: "90px", maxWidth: "75px" }}>
              <ReferenceManyField
                reference="organization_user"
                target="user_id"
              >
                <SingleFieldList>
                  <ReferenceField
                    source="organization_id"
                    reference="organization"
                    link="show"
                  >
                    <TextField label="이름" source="name" />
                  </ReferenceField>
                </SingleFieldList>
              </ReferenceManyField>
            </div>
          )}
        />
        <TextField source="timezone" />
        <TextField source="from_where" label="가입경로" />
        <TextField source="utm_source" label="utm_source" />
        <TextField source="utm_campaign" label="utm_campaign" />
        <FunctionField
          label="provider"
          render={(record) => {
            if (record.kakao_user_id) {
              if (record.is_kakao_plus_friend === true)
                return "kakao(채널추가)";
              else return "kakao";
            }
            if ((record.provider_data ?? []).length === 0) return null;

            return record.provider_data[0].providerId;
          }}
        />
        <ImageField source="photo_url" label="프로필이미지" />
        <FunctionField
          label="role"
          render={(record) => {
            if (record.role === "host") return <ChipField source="role" />;
          }}
        />

        <TextField source="company" label="회사(학교)" />
        <TextField source="team" label="부서(전공)" />
        <TextField source="position" label="직책" />
        <TextField source="birth_year" label="출생년도" />
        <TextField source="gender" label="성별" />
        <TextField source="mobile" label="전화번호" />
        <TextField source="bank_account" label="은행계좌" />
        <FunctionField
          label="이벤트 수신 알림 동의"
          render={(record) => {
            if (record.agreement_email)
              return <BooleanField source="agreement_terms" />;
          }}
        />
        <FunctionField
          label="가입동의"
          render={(record) => {
            if (
              record.agreement_terms &&
              record.agreement_privacy &&
              record.agreement_adult
            )
              return <BooleanField source="agreement_terms" />;
          }}
        />
        <BooleanField
          label="계정이 활성 상태 유지 동의"
          source="agreement_account_activation"
        />
        <BooleanField
          label="게스트 가이드라인 동의"
          source="agreement_guest_guideline"
        />
        <JsonField source="provider_data" />
        <DateField label="Created At" source="created_at" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="display_name" label="이름" disabled />
      <TextInput source="email" label="이메일" disabled />
      <TextInput source="email2" />
    </SimpleForm>
  </Edit>
);

const QUERY_USER_EMAILS = gql`
  query QueryUserEmails {
    user(where: { agreement_terms: { _eq: true } }) {
      display_name
      email
      email2
    }
  }
`;
