import { gql } from "@apollo/client";
import LaunchIcon from "@mui/icons-material/Launch";
import dayjs from "dayjs";
import { useState } from "react";
import {
  Button,
  CheckboxGroupInput,
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListButton,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";
import { apiAction } from "../apiAction";
import ChangeMeetingDialog from "../components/ChangeMeetingDialog";
import ForceCompleteDialog from "../components/order/ForceCompleteDialog";
import MeetingCount from "../components/order/MeetingCount";
import { apolloClient } from "../lib/apollo";
import { isAdmin } from "../lib/auth";
import { trim } from "../util";

// const useQuickFilterStyles = makeStyles((theme) => ({
//   chip: {
//     marginBottom: "0.5rem",
//   },
// }));
// const StatusFilter = ({ label }) => {
//   const classes = useQuickFilterStyles();
//   return <Chip className={classes.chip} label={label} />;
// };

const filters = [
  <TextInput label="호스트" source="description" alwaysOn />,
  <TextInput label="게스트" source="guest_name" alwaysOn />,
  <CheckboxGroupInput
    source="order_status"
    label="결제 상태"
    choices={[
      { id: "canceled", name: "취소" },
      { id: "paid", name: "결제완료" },
      { id: "completed", name: "대화종료" },
      { id: "closed", name: "정산완료" },
      { id: "refunded", name: "환불" },
    ]}
    alwaysOn
  />,
  // <CheckboxGroupInput
  //   source="guest_organization_id"
  //   label="소속"
  //   choices={[
  //     { id: 4, name: "서포터즈" },
  //     { id: 3, name: "이화여대" },
  //   ]}
  //   alwaysOn
  // />,
];

export const OrderList = (props) => {
  const redirect = useRedirect();
  return (
    <List filters={filters} {...props}>
      <Datagrid
        // rowClick="show"
        bulkActionButtons={false}
        style={{ width: "1670px" }}
      >
        <FunctionField
          label="id"
          render={(record) => (
            <Link to={`/order_view/${record.id}/show`}>{record.id}</Link>
          )}
        />
        <ReferenceField
          label="호스트"
          source="host_id"
          reference="profile"
          link="show"
        >
          <TextField label="이름" source="name" />
        </ReferenceField>
        <ReferenceField
          label="게스트"
          source="guest_id"
          reference="user"
          link="show"
        >
          <TextField label="이름" source="display_name" />
        </ReferenceField>
        <FunctionField
          label="대화 이력"
          render={(record) => {
            return (
              <Button
                variant="text"
                onClick={async (e) => {
                  const { data } = await apolloClient.query({
                    query: GET_THREAD_BY_HOST_GUEST,
                    variables: {
                      host_id: record.host_id,
                      guest_id: record.guest_id,
                    },
                  });
                  if (data.thread.length > 0) {
                    redirect(`/thread_view/${data.thread[0].id}/show`);
                  }
                }}
              >
                보기&nbsp;
                <LaunchIcon />
              </Button>
            );
          }}
        />
        {/* <FunctionField
        label="소속"
        render={(record) => (
          <div style={{ minWidth: "90px", maxWidth: "75px" }}>
            {organizationInfo(record.guest_organization_id)}
          </div>
        )}
      /> */}
        <FunctionField
          label="제목"
          render={(record) => trim(record.profile_title, 20)}
        />
        <TextField source="order_status" label="주문 상태" />
        {/* <TextField source="meeting_count" label="대화횟수" /> */}
        <FunctionField
          label="대화횟수"
          render={(record) => <MeetingCount order={record} />}
        />
        <NumberField source="total_amount" label="가격" />
        <TextField source="method" label="결제수단" />
        <NumberField source="pay_amount" label="결제금액" />
        <NumberField source="refund_amount" label="환불금액" />
        <FunctionField
          label="대화 상태"
          render={(record) => {
            if (!record.group_meeting_id) {
              return (
                record.meeting_status ??
                (record.order_status === "paid" ? "조율중" : "")
              );
            } else {
              return "";
            }
          }}
        />
        <FunctionField
          label="대화시간"
          render={(record) => {
            if (record.start_at) {
              return <DateField source="start_at" label="대화시간" showTime />;
            }
            if (record.request_meeting_times) {
              return (
                <>
                  게스트 제안 시간:
                  {record.request_meeting_times.map((t) => (
                    <div>{dayjs(t.start_at).format("M/D HH:ss")}</div>
                  ))}
                </>
              );
            }
          }}
        />

        <DateField source="created_at" label="결제시간" showTime />
      </Datagrid>
    </List>
  );
};

const OrderTitle = ({ record }) => {
  return (
    <span>
      Order:{" "}
      {record ? `[${record.user_display_name}] ${record.description}` : ""}
    </span>
  );
};

const OrderShowActions = () => {
  const record = useRecordContext();
  const [openRefund, setOpenRefund] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);
  const [openHandleOrderPaid, setOpenHandleOrderPaid] = useState(false);
  const [loadingHandleOrderPaid, setLoadingHandleOrderPaid] = useState(false);
  const refresh = useRefresh();

  const handleRefund = async () => {
    setLoadingRefund(true);
    const res = await apiAction("/order/refund", {
      orderId: record.id,
      method: record.method,
      force: true,
    });
    if (res.success === false) {
      alert(res.error.message);
    } else {
      refresh();
    }
    setLoadingRefund(false);
    setOpenRefund(false);
  };

  const handleOrderPaid = async () => {
    setLoadingHandleOrderPaid(true);
    const res = await apiAction("/admin/order/handle-paid", {
      orderId: record.id,
    });
    if (res.success === false) {
      alert(res.error.message);
    } else {
      refresh();
    }
    setLoadingHandleOrderPaid(false);
    setOpenHandleOrderPaid(false);
  };

  if (!isAdmin()) {
    return (
      <TopToolbar>
        <ListButton record={record} label="Back" />
      </TopToolbar>
    );
  }

  if (!record) return null;

  return (
    <TopToolbar>
      {record?.order_status === "paid" && (
        <ChangeMeetingDialog order={record} refresh={refresh} />
      )}
      <ForceCompleteDialog order={record} />
      <Button
        disabled={record?.order_status !== "paid"}
        onClick={() => setOpenConfirmAlert(true)}
        label="일정 수락 알림 메세지 보내기"
      />
      <Confirm
        isOpen={openConfirmAlert}
        loading={loadingRefund}
        title="일정 수락 알림 메세지 내용"
        content={
          <>
            <br />
            {record.guest_name}님께서 대화 신청을 하셨습니다.
            <br />
            <br />
            ◼︎ 요청한 대화 시간
            <br />
            ...
            <br />
            <br />
            ◼︎ 나누고 싶은 이야기
            <br />
            ...
            <br />
            <br />
            {record.profile_name}님이 일정을 최종적으로 확정하면 대화가
            이루어집니다.
          </>
        }
        cancel="취소"
        confirm="전송하기"
        onConfirm={() => {
          apiAction("/task", {
            action: "send-meeting-confirm-alert-message",
            body: {
              orderId: record.id,
            },
          });
        }}
        onClose={() => setOpenConfirmAlert(false)}
      />

      <Button
        disabled={record?.order_status !== "paid"}
        onClick={() => setOpenRefund(true)}
        label="강제환불"
      />
      <Confirm
        isOpen={openRefund}
        loading={loadingRefund}
        title="강제 환불"
        content="정말 환불 할꺼에요? 취소 못함!!"
        onConfirm={handleRefund}
        onClose={() => setOpenRefund(false)}
      />

      <Button
        disabled={
          record?.order_status !== "paid" ||
          record?.meeting_status !== "request"
        }
        onClick={() => setOpenHandleOrderPaid(true)}
        label="지불 후 처리(줌미팅 생성, 메세지 발송)"
      />
      <Confirm
        isOpen={openHandleOrderPaid}
        loading={loadingHandleOrderPaid}
        title="지불 후 처리 강제 실행"
        content="지불 후 처리(줌미팅 생성, 메세지 발송)를 강제로 실행합니다."
        onConfirm={handleOrderPaid}
        onClose={() => setOpenHandleOrderPaid(false)}
      />

      <ListButton record={record} label="Back" />
    </TopToolbar>
  );
};

export const OrderShow = (props) => {
  // const { data } = useGetList("order_event", {
  //   filter: { order_id: props.id },
  // });
  const redirect = useRedirect();

  return (
    <Show title={<OrderTitle />} actions={<OrderShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />

          <ReferenceField
            label="게스트"
            source="guest_id"
            reference="user"
            link="show"
          >
            <TextField label="이름" source="display_name" />
          </ReferenceField>
          <FunctionField
            label="소속"
            render={(record) => (
              <div style={{ minWidth: "90px", maxWidth: "75px" }}>
                <ReferenceManyField
                  reference="organization_user"
                  source="guest_id"
                  target="user_id"
                >
                  <SingleFieldList>
                    <ReferenceField
                      source="organization_id"
                      reference="organization"
                      link="show"
                    >
                      <TextField label="이름" source="name" />
                    </ReferenceField>
                  </SingleFieldList>
                </ReferenceManyField>
              </div>
            )}
          />
          <ReferenceField
            label="호스트"
            source="host_id"
            reference="profile"
            link="show"
          >
            <TextField label="이름" source="name" />
          </ReferenceField>
          <TextField source="order_status" label="주문 상태" />
          <FunctionField
            label="대화횟수"
            render={(record) => {
              if (record.meeting_count === 1) return 1;
              return <MeetingCount orderStatus={record} detailed />;
            }}
          />
          <NumberField source="total_amount" label="가격" />
          <TextField source="method" label="결제수단" />
          <TextField source="pay_amount" label="결제금액" />
          <TextField source="refund_amount" label="환불금액" />
          <FunctionField
            label="대화 상태"
            render={(record) => {
              if (!record.group_meeting_id) {
                return (
                  record.meeting_status ??
                  (record.order_status === "paid" ? "조율중" : "")
                );
              } else {
                return "";
              }
            }}
          />
          <FunctionField
            label="대화시간"
            render={(record) => {
              if (record.start_at) {
                return (
                  <DateField source="start_at" label="대화시간" showTime />
                );
              }
              if (record.request_meeting_times) {
                return (
                  <>
                    게스트 제안 시간:
                    {record.request_meeting_times.map((t) => (
                      <div>{dayjs(t.start_at).format("M/D HH:ss")}</div>
                    ))}
                  </>
                );
              }
            }}
          />
          <TextField source="zoom_join_url" label="줌 접속 주소" />
          <FunctionField
            label="자기 소개"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    (record.guest_memo?.introduction ?? "").replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br>"
                    ) ?? "",
                }}
              ></div>
            )}
          />
          <FunctionField
            label="호스트와 나누고 싶은 이야기"
            render={(record) => (
              <ul>
                {(record.guest_memo?.points ?? []).map((p) => (
                  <li key={p.id}>{p.title}</li>
                ))}
              </ul>
            )}
          />
          {/* <FunctionField
            label="event"
            render={() =>
              (data ?? []).map((e) => (
                <Box key={e.id} sx={{ mb: 2 }}>
                  <div>
                    <Chip label={e.status} />{" "}
                    {dayjs(e.created_at).format("MM.DD A hh:mm:ss")}
                  </div>
                  {JSON.stringify(e.payload)}
                </Box>
              ))
            }
          /> */}

          <DateField source="created_at" showTime />
          <FunctionField
            label="대화 이력"
            render={(record) => {
              return (
                <Button
                  variant="text"
                  onClick={async (e) => {
                    const { data } = await apolloClient.query({
                      query: GET_THREAD_BY_HOST_GUEST,
                      variables: {
                        host_id: record.host_id,
                        guest_id: record.guest_id,
                      },
                    });
                    if (data.thread.length > 0) {
                      redirect(`/thread_view/${data.thread[0].id}/show`);
                    }
                  }}
                >
                  보기&nbsp;
                  <LaunchIcon />
                </Button>
              );
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const organizationInfo = (organizationId) => {
  switch (organizationId) {
    case 1:
      return "중앙예닮학교";
    case 3:
      return "이화여대";
    case 4:
      return "서포터즈";
    default:
      return "";
  }
};

export const GET_THREAD_BY_HOST_GUEST = gql`
  query GetThreadByHostGuest($host_id: bigint!, $guest_id: bigint!) {
    thread(
      where: { host_id: { _eq: $host_id }, guest_id: { _eq: $guest_id } }
      limit: 1
    ) {
      id
    }
  }
`;
