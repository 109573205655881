import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Container, Grid, Box, CircularProgress } from "@mui/material";
import { useUpdate } from "react-admin";
import ImageBox from "./ImageBox";
import ImageEditBox from "./ImageEditBox";
import { apiAction } from "../../apiAction";

const uploadToBucket = async (preSignedUrl, file) => {
  const option = {
    method: "PUT",
    body: file,
  };

  await fetch(preSignedUrl, option);
};

const getExtension = (s) =>
  (s.substring(s.lastIndexOf(".") + 1) ?? "").toLowerCase();
const getFileType = (extension) => {
  if (["png", "jpg", "jpeg", "gif", "webp", "bmp", "svg"].includes(extension)) {
    return "image";
  } else {
    return "video";
  }
};

export default function ImgUpload({ profile }) {
  const [update] = useUpdate();
  const [processingIndex, setProcessingIndex] = useState(undefined);
  const [editingFile, setEditingFile] = useState();

  const updateHost = async (data) => {
    await update("profile", {
      id: profile.id,
      data: data.variables.profile,
    });
  };

  const setMainImage = (image) => {
    updateHost({
      variables: { id: profile.id, profile: { main_image: image } },
    });
  };

  const deleteImage = async (i) => {
    setProcessingIndex(i);

    const image = profile.images[i];
    await apiAction("/host/file/delete", { fileUrl: image });

    const fields =
      profile.images === undefined
        ? []
        : {
            images: [
              ...profile.images.slice(0, i),
              ...profile.images.slice(i + 1),
            ],
          };
    if (image === profile.main_image) {
      fields["main_image"] = null;
    }

    updateHost({
      variables: { id: profile.id, profile: fields },
    });

    setProcessingIndex(undefined);
  };

  const uploadFile = async (file) => {
    const suffix = getExtension(file.name);
    const fileType = getFileType(suffix);

    setProcessingIndex(
      fileType === "video" ? "video" : (profile.images ?? []).length
    );

    const resp = await apiAction("/host/file/upload-url", {
      fileType,
      suffix,
      userId: profile.user_id,
    });

    const preSignedUrl = new URL(resp.data);

    await uploadToBucket(preSignedUrl, file);

    const fileUrl = `${preSignedUrl.protocol}//${preSignedUrl.host}${preSignedUrl.pathname}`;

    const field =
      fileType === "video"
        ? { video: { original: fileUrl } }
        : { images: [...(profile.images ?? []), fileUrl] };

    if (fileType === "image" && !profile.main_image) {
      field["main_image"] = fileUrl;
    }

    updateHost({
      variables: { id: profile.id, profile: field },
    });

    if (fileType === "video") {
      apiAction("/host/convert-video", { original: fileUrl });
    }

    setProcessingIndex(undefined);
  };

  const buildImageBox = (index) => {
    if (processingIndex === index) {
      return (
        <UploadBox>
          <CircularProgress />
        </UploadBox>
      );
    }
    if ((profile.images ?? []).length > index) {
      return (
        <ImageBox
          host={profile}
          src={profile.images[index]}
          setMainImage={setMainImage}
          deleteImage={() => deleteImage(index)}
          processing={processingIndex !== undefined}
        />
      );
    }
    return (
      <ImageEditBox
        file={editingFile}
        uploadFile={uploadFile}
        processing={processingIndex !== undefined}
      />
    );
  };

  return (
    <ImgUploadWrap>
      <ImgList>
        <Grid container spacing={2}>
          <Grid item md={3}>
            {buildImageBox(0)}
          </Grid>
          <Grid item md={3}>
            {buildImageBox(1)}
          </Grid>
          <Grid item md={3}>
            {buildImageBox(2)}
          </Grid>
          {(profile.images ?? []).slice(3).map((image, i) => (
            <Grid key={image} item md={3}>
              {buildImageBox(3 + i)}
            </Grid>
          ))}
          <Grid key={"uploadBox"} item md={3}>
            <ImageEditBox
              file={editingFile}
              uploadFile={async (f) => {
                await uploadFile(f);
                setEditingFile(undefined);
              }}
              processing={processingIndex !== undefined}
            />
          </Grid>
        </Grid>
      </ImgList>
    </ImgUploadWrap>
  );
}

const ImgUploadWrap = styled(Container)`
  padding-bottom: 3rem;
  width: 860px;
  h3 {
    font-weight: 600;
    line-height: 1.6;
  }
  h4 {
    font-weight: 500;
    line-height: 1.6;
  }
  .subText {
    margin: 2rem 0 4rem 0;
    h4:first-of-type {
      font-weight: 600;
    }
  }
`;

const ImgList = styled(Box)`
  margin-top: 2rem;
`;

const UploadBox = styled(Box)`
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 5rem;
    height: 5rem;
  }
`;
