import { gql } from "@apollo/client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "react-admin";
import { apolloClient } from "../lib/apollo";

export default function HomePage() {
  const [profiles, setProfiles] = useState([]);
  const [timetableUserIds, setTimetableUserIds] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await apolloClient.query({
        query: QUERY_PROFILE_AVAILABLE_MEETING_COUNT,
      });
      let list = data.profile ?? [];

      list = list
        .slice()
        .sort(
          (a, b) =>
            a.meetings_aggregate.aggregate.count -
            b.meetings_aggregate.aggregate.count
        );

      setProfiles(list);
    })();

    (async () => {
      const { data } = await apolloClient.query({
        query: QUERY_TIMETABLE_AGGREGATE,
      });

      setTimetableUserIds(
        (data?.timetable_aggregate?.nodes ?? []).map((n) => n.user_id)
      );
    })();
  }, []);

  console.log("timetableUserIds:", timetableUserIds);

  const total = profiles.length;
  const openHostCount = profiles.filter(
    (p) => p.meetings_aggregate.aggregate.count === 0
  ).length;
  const closeHostCount = profiles.filter(
    (p) => p.meetings_aggregate.aggregate.count > 0
  ).length;

  return (
    <>
      <TableContainer component={Paper}>
        <h2 style={{ marginLeft: "1rem" }}>열린 미팅 수</h2>
        <p style={{ marginLeft: "1.5rem" }}>전체 호스트 수: {total}명</p>
        <ul>
          <li>
            일정 등록 안 된 호스트 수: {openHostCount}명 (
            {Math.round((openHostCount / total) * 100)}%)
          </li>
          <li>
            일정 등록 된 호스트 수: {closeHostCount}명 (
            {Math.round((closeHostCount / total) * 100)}%)
          </li>
        </ul>

        <Table sx={{ maxWidth: 900 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>이름</TableCell>
              <TableCell>제목</TableCell>
              <TableCell align="right">열린 미팅수</TableCell>
              <TableCell>스케줄 등록</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => (
              <TableRow
                key={profile.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" width={120}>
                  <Link to={`/profile/${profile.id}/show`}>{profile.name}</Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  {profile.title}
                </TableCell>
                <TableCell align="right" width={100}>
                  {profile.meetings_aggregate.aggregate.count}
                </TableCell>
                <TableCell align="center" width={100}>
                  {timetableUserIds.includes(profile.id) ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <HighlightOffIcon color="error" />
                  )}
                </TableCell>
                <TableCell width={80}>
                  <a
                    href={`https://www.hithere.co/${profile.slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hithere.co/{profile.slug}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
const QUERY_TIMETABLE_AGGREGATE = gql`
  query QueryTimetableAggregate {
    timetable_aggregate(
      distinct_on: user_id
      where: { enable: { _eq: true } }
    ) {
      aggregate {
        count
      }
      nodes {
        user_id
      }
    }
  }
`;
const QUERY_PROFILE_AVAILABLE_MEETING_COUNT = gql`
  query QueryHostAvailbleMeetingCount {
    profile(where: { is_published: { _eq: true } }) {
      id
      user_id
      name
      title
      slug
      meetings_aggregate(
        where: { start_at: { _gt: now }, status: { _eq: "created" } }
      ) {
        aggregate {
          count(columns: start_at)
        }
      }
    }
  }
`;
