import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiAction } from "../apiAction";

export default function SummaryPage() {
  const [monthList, setMonthList] = useState([]);
  const [dateList, setDateList] = useState([]);

  function getLastSevenMonths() {
    const now = dayjs();
    const months = [];
    for (let i = 0; i < 8; i++) {
      months.push(now.subtract(i, "month").format("YYYY-MM"));
    }
    return months;
  }

  function getLastSevenDates() {
    const now = dayjs();
    const dates = [];
    for (let i = 0; i < 8; i++) {
      dates.push(now.subtract(i, "day").format("YYYY-MM-DD"));
    }
    return dates;
  }

  useEffect(() => {
    apiAction("/task", {
      action: "get-event-count",
      body: { months: getLastSevenMonths() },
    }).then((data) =>
      setMonthList((data ?? []).filter((t) => t?.month).slice(0, 7))
    );

    apiAction("/task", {
      action: "get-event-count",
      body: { dates: getLastSevenDates() },
    }).then((data) =>
      setDateList((data ?? []).filter((t) => t?.date).slice(0, 7))
    );
  }, []);

  return (
    <TableContainer component={Paper}>
      <h3 style={{ marginLeft: "10px" }}>지난 7일</h3>
      <Table sx={{ width: 1040 }}>
        <TableHead>
          <TableRow>
            {dateList.map((t) => (
              <TableCell key={t.date}>{t.date}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {dateList.map((t) => (
              <TableCell key={t.date}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>회원가입:</span>
                  {t.signup_count}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>일반대화 결제:</span>
                  {t.chat_order_count}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>코칭 결제:</span>
                  {t.coaching_order_count}
                </p>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <br />
      <br />
      <br />
      <h3 style={{ marginLeft: "10px" }}>지난 7개월</h3>
      <Table sx={{ width: 1040 }}>
        <TableHead>
          <TableRow>
            {monthList.map((t) => (
              <TableCell key={t.month}>{t.month}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {monthList.map((t) => (
              <TableCell key={t.month}>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>회원가입:</span>
                  {t.signup_count}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>일반대화 결제:</span>
                  {t.chat_order_count}
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginRight: "20px",
                  }}
                >
                  <span>코칭 결제:</span>
                  {t.coaching_order_count}
                </p>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
