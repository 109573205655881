import { styled } from "@mui/material/styles";
import { Box, CardMedia, Badge } from "@mui/material";
import ActionButton from "./ActionButton";

export default function ImageBox({
  host,
  src,
  setMainImage,
  deleteImage,
  processing,
}) {
  const deleteImageAction = {
    name: "삭제",
    action: () => deleteImage(),
    confirm: {
      message: "이 사진을 삭제하시겠어요?",
      info: "한 번 삭제된 사진은 복원될 수 없습니다.",
      okText: "삭제",
    },
  };
  const setMainImageAction = {
    name: "메인 사진 지정",
    action: () => setMainImage(src),
    confirm: {
      message: "메인 사진으로 지정합니다.",
    },
  };

  return (
    <ImgBox>
      <StyledBadge
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        badgeContent={"메인 사진"}
        color="secondary"
        invisible={host.main_image !== src}
      >
        <StyldCardMedia
          component="img"
          height={280}
          image={src}
          alt="호스트이미지"
        />
        <div className="imgbtn">
          {!processing && (
            <ActionButton actions={[deleteImageAction, setMainImageAction]} />
          )}
        </div>
      </StyledBadge>
    </ImgBox>
  );
}

const ImgBox = styled(Box)`
  position: relative;
  .imgbtn {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const StyldCardMedia = styled(CardMedia)`
  border-radius: 4px;
  background-color: #000;
`;

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    left: 45,
    top: 16,
    border: `2px solid`,
    padding: "13px",
  },
}));
