import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  NumberField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  Edit,
  SimpleForm,
  EditButton,
  FunctionField,
  ReferenceField,
} from "react-admin";
import { isAdmin } from "../lib/auth";

const ReviewFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by content" source="content" alwaysOn />
  </Filter>
);

export const ReviewList = (props) => (
  <List filters={<ReviewFilter />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField
        label="게스트"
        source="user_id"
        reference="user"
        link="show"
      >
        <TextField source="display_name" />
      </ReferenceField>
      <ReferenceField
        label="호스트"
        source="host_id"
        reference="profile"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="score" label="점수" />
      <FunctionField
        label="내용"
        render={(record) => (
          <div style={{ maxWidth: "800px" }}>{record.content}</div>
        )}
      />
      <DateField source="created_at" label="작성시간" />
    </Datagrid>
  </List>
);

const ReviewTitle = ({ record }) => {
  return <span>Review: {record ? `${record.name}` : ""}</span>;
};

const ReviewShowActions = ({ data }) => (
  <TopToolbar>
    <ListButton record={data} />
    {isAdmin() && <EditButton record={data} />}
  </TopToolbar>
);

export const ReviewShow = (props) => (
  <Show title={<ReviewTitle />} actions={<ReviewShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <ReferenceField
          label="User"
          source="user_id"
          reference="user"
          link="show"
        >
          <TextField source="display_name" />
        </ReferenceField>
        <ReferenceField
          label="Host"
          source="host_id"
          reference="profile"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="score" />
        <FunctionField
          label="내용"
          render={(record) => (
            <div
              style={{ width: "600px" }}
              dangerouslySetInnerHTML={{
                __html:
                  record.content?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
              }}
            ></div>
          )}
        />
        <DateField label="Created At" source="created_at" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const ReviewEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <NumberField source="id" />
      <TextField source="id" />
      <NumberField source="host_id" />
      <NumberField source="user_id" />
      <NumberField source="score" />
      <TextInput
        source="content"
        multiline
        fullWidth
        style={{ width: "700px" }}
      />
      <DateField label="생성날짜" source="created_at" />
      <DateField label="수정날짜" source="updated_at" />
    </SimpleForm>
  </Edit>
);
