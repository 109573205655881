import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";

import { accessToken } from "./auth";

const authLink = new ApolloLink(async (operation, forward) => {
  const token = await accessToken();

  if (token) {
    operation.setContext({
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(
    new HttpLink({ uri: process.env.REACT_APP_HASURA_ADDR })
  ),
  cache: new InMemoryCache(),
});
