import { useState } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)`
`;

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ActionButton({ actions }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setActiveAction(undefined);
    setAnchorEl(null);
  };

  const [activeAction, setActiveAction] = useState(undefined);

  return (
    <>
      <ImgButtonBox>
        <StyledIconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <MoreHorizIcon />
        </StyledIconButton>

        <StyledMenu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {actions?.map((action) => (
            <StyledMenuItem
              key={action.name}
              onClick={() => setActiveAction(action)}
            >
              {action.name}
            </StyledMenuItem>
          ))}
        </StyledMenu>

        <StyledBootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={activeAction !== undefined}
        >
          <StyledBootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            {activeAction?.confirm?.message}
          </StyledBootstrapDialogTitle>

          {activeAction?.confirm?.info && (
            <DialogContent dividers>
              <StyledTypography gutterBottom>
                {activeAction.confirm.info}
              </StyledTypography>
            </DialogContent>
          )}

          <DialogActions>
            <div className="btnWrap">
              <Button autoFocus onClick={handleClose}>
                {activeAction?.confirm?.cancelText ?? "취소"}
              </Button>
              <Button
                autoFocus
                variant="contained"
                onClick={() => {
                  activeAction?.action();
                  handleClose();
                }}
              >
                {activeAction?.confirm?.cancelText ?? "확인"}
              </Button>
            </div>
          </DialogActions>
        </StyledBootstrapDialog>
      </ImgButtonBox>
    </>
  );
}

const ImgButtonBox = styled(Box)`
  svg {    
    width: 3rem;
    height: 3rem;
  }
`;

const StyledMenu = styled(Menu)`
  top: 50px;
  left: -20px;
`;

const StyledIconButton = styled(IconButton)`
  background-color: rgba(255, 255, 255, 0.5);
  margin: 1rem;
`;

const StyledMenuItem = styled(MenuItem)`
  font-weight: 600;
  font-family: "Pretendard-Regular";
`;

const StyledBootstrapDialog = styled(BootstrapDialog)`
  .btnWrap {
    display: flex;
    align-itmes: center;
    justify-content: space-between;
    width: 100%;
    button {
      font-family: "Pretendard-Regular";
      font-weight: 400;
      box-shadow: none;
    }
    button:first-of-type {
      background-color: transparent;
    }
  }
`;

const StyledBootstrapDialogTitle = styled(BootstrapDialogTitle)`
  min-width: 250px;
  font-weight: 600;
  font-family: "Pretendard-Regular";
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const StyledTypography = styled(Typography)`
  font-weight: 400;
  font-family: "Pretendard-Regular";
`;
