import {
  TextareaAutosize,
  Radio,
  RadioGroup,
  FormControlLabel,
  Stack,
  Button,
} from "@mui/material";
import { useState } from "react";
import SelectHostForm from "./SelectHostForm";
import { apiAction } from "../../apiAction";

export default function Query() {
  const [text, setText] = useState("");
  const [source, setSource] = useState("part");
  const [selectedHost, setSelectedHost] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);

  async function postChatGPT() {
    setLoading(true);
    setResponse({});

    const { data } = await apiAction("/gpt/post", {
      targetType: source === "none" ? "" : "host",
      hostIds: selectedHost.map((h) => h.user_id),
      prompt: text,
    });

    setResponse(data);
    setLoading(false);
    // setResponse(
    //   JSON.parse(
    //     `{"id":"chatcmpl-74mYzBZmn8ZC4B8eN5E6gjjN2fddz","object":"chat.completion","created":1681374345,"model":"gpt-3.5-turbo-0301","usage":{"prompt_tokens":1349,"completion_tokens":189,"total_tokens":1538},"choices":[{"message":{"role":"assistant","content":"'나의 꿈을 지지해 줄 멘토' 문구가 이 사람에게 어울릴 것 같습니다. 이 사람은 다양한 경험을 가지고 있으며, 여러 분야에서 일해본 경험이 있습니다. 그러나 아프리카 음악과 같이 개인적인 취미를 가지고 있으며, ChatGPT에도 관심이 있습니다. 이러한 다양한 취향과 경험을 지닌 사람이 누군가의 멘토가 된다면, 그들의 꿈을 지지해주고 조언해줄 수 있을 것으로 보입니다."},"finish_reason":"stop","index":0}]}`
    //   )
    // );
  }

  return (
    <>
      <h3>대상</h3>
      <RadioGroup
        row
        aria-label="position"
        name="position"
        value={source}
        onChange={(event) => setSource(event.target.value)}
        disabled
      >
        <FormControlLabel
          value="none"
          control={<Radio />}
          label="없음"
          disabled
        />
        <FormControlLabel
          value="all"
          control={<Radio />}
          label="모든호스트"
          disabled
        />
        <FormControlLabel
          value="part"
          control={<Radio />}
          label="일부"
          disabled
        />
        {source === "part" && (
          <Stack direction="row" alignItems="center">
            <SelectHostForm onSelect={(list) => setSelectedHost(list)} />
            <span>
              {selectedHost.map((h) => `${h.name}(${h.user_id})`).join(",")}
            </span>
          </Stack>
        )}
      </RadioGroup>

      <h3>
        아무거나 물어보세요
        <Button
          variant="contained"
          style={{ position: "absolute", left: "800px" }}
          onClick={() => postChatGPT()}
          disabled={selectedHost.length === 0}
        >
          HiGPT에게 물어보기
        </Button>
      </h3>
      <TextareaAutosize
        value={text}
        onChange={(e) => setText(e.target.value)}
        minRows={10}
        style={{ width: "700px" }}
      />
      {(response?.choices ?? []).length > 0 && (
        <p style={{ width: "700px", lineHeight: 1.6 }}>
          {response.choices[0].message.content}
        </p>
      )}
      {loading && <p>로딩중...</p>}
    </>
  );
}
