import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import Calendar from "tui-calendar";
import "tui-calendar/dist/tui-calendar.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import { gql } from "@apollo/client";
import { apolloClient } from "../lib/apollo";
import { RadioGroup, FormControlLabel, Radio, IconButton } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

export default function MeetingSchedulePage() {
  const [currentView, setCurrentView] = useState("month");
  const [displayDate, setDisplayDate] = useState("");
  const calendarRef = useRef(null);
  const calendarInstance = useRef(null);

  useEffect(() => {
    calendarInstance.current = new Calendar(calendarRef.current, {
      defaultView: "month",
      isReadOnly: true,
      useFormPopup: true,
      useDetailPopup: true,
      template: {
        popupDetailBody: (schedule) => {
          return `
            <a href="/#/meeting_status/${schedule.id}/show">
              <span>${schedule.body}</span>
            </a>
          `;
        },
      },
    });

    calendarInstance.current.changeView(currentView, true);

    const fetchData = async () => {
      try {
        const startOfLastMonth = dayjs()
          .subtract(1, "month")
          .startOf("month")
          .toISOString();

        const { data } = await apolloClient.query({
          query: QUERY_MEETING_STATUS,
          variables: {
            start_at: startOfLastMonth,
          },
        });

        if (data.meeting) {
          data.meeting.forEach((meeting) => {
            calendarInstance.current.createSchedules([
              {
                id: meeting.id,
                calendarId: "1",
                bgColor: "rgb(120, 222, 273)",
                title: `${meeting.host.name} / ${meeting.guest.display_name}`,
                body: "자세히 보기",
                category: "time",
                attendees: [
                  `${meeting.host.name}(${meeting.host_id})`,
                  `${meeting.guest.display_name}(${meeting.guest_id})`,
                ],
                start: meeting.start_at,
                end: meeting.end_at,
                state: meeting.status,
              },
            ]);
          });
        }
      } catch (error) {
        console.error("Error loading meeting status", error);
      }
    };

    fetchData().catch((error) => {
      console.error("Unhandled error in fetchData", error);
    });

    changeDisplayDate();

    return () => {
      if (calendarInstance.current) {
        calendarInstance.current.destroy();
      }
    };
  }, [currentView]);

  const changeDisplayDate = () => {
    switch (calendarInstance.current.getViewName()) {
      case 'month':
        const renderedDate = calendarInstance.current.getDate();
        setDisplayDate(
          `${renderedDate.getFullYear()}년 ${renderedDate.getMonth() + 1}월`
        );
        break;
      case 'week':
        const rangeStart = calendarInstance.current
          .getDateRangeStart()
          .toDate();
        const rangeEnd = calendarInstance.current.getDateRangeEnd().toDate();
        const format = 'YYYY-MM-DD';
        setDisplayDate(
          `${dayjs(rangeStart).format(format)} ~ ${dayjs(rangeEnd).format(
            format
          )}`
        );
        break;
      default:
        console.error('View name is invalid');
        break;
    }
  };  

  return (
    <div style={{ height: "80vh", paddingTop: "5vh" }}>
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={movePrev}>
            <ArrowLeft />
          </IconButton>
          <span>{displayDate}</span>
          <IconButton onClick={moveNext}>
            <ArrowRight />
          </IconButton>
        </div>
        <RadioGroup
          row
          aria-labelledby="view-type-radio-button-group"
          name="viewType"
          value={currentView}
          onChange={(e) => setCurrentView(e.target.value)}
        >
          <FormControlLabel value="month" control={<Radio />} label="Month" />
          <FormControlLabel value="week" control={<Radio />} label="Week" />
        </RadioGroup>
      </header>
      <div ref={calendarRef}></div>
    </div>
  );

  function movePrev() {
    if (calendarInstance.current) {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      lastMonthDate.setDate(1);

      const startRange = calendarInstance.current.getDateRangeStart().toDate();

      if (startRange > lastMonthDate) {
        calendarInstance.current.prev();
        changeDisplayDate();
      }
    }
  }

  function moveNext() {
    if (calendarInstance.current) {
      calendarInstance.current.next();
      changeDisplayDate();
    }
  }
}

const QUERY_MEETING_STATUS = gql`
  query MyQuery($start_at: timestamptz!) {
    meeting(
      where: { status: { _eq: "reserved" }, start_at: { _gt: $start_at } }
    ) {
      id
      guest_id
      host_id
      guest {
        id
        display_name
      }
      host: profile {
        name
        title
      }
      status
      start_at
      end_at
      guest_entered_at
      host_entered_at
    }
  }
`;
