import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { FirebaseAuthProvider } from "react-admin-firebase";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

let _accessToken;

export const authProvider = FirebaseAuthProvider(config);

export async function accessToken() {
  if (!_accessToken) {
    _accessToken = await authProvider.getJWTToken();
  }

  if (isTokenExpired(_accessToken)) {
    const auth = getAuth(getApp());
    const { token } = await auth.currentUser?.getIdTokenResult(true);
    _accessToken = token;
  }

  return _accessToken;
}
export function isAdmin() {
  const currentUserEmail = getAuth(getApp()).currentUser?.email;
  return currentUserEmail === "hi@hithere.co";
}

// 토큰 만료 시간 확인
const isTokenExpired = (token) => {
  const decodedToken = parseJwt(token);
  if (decodedToken && decodedToken.exp) {
    const currentTime = Date.now() / 1000; // 현재 시간 (단위: 초)
    return currentTime + 60 * 10 > decodedToken.exp; // 10분 이내 만료시간이면 토큰 만료로 간주
  }
  return true; // 토큰 파싱에 실패하거나 exp 클레임이 없을 경우 토큰은 만료된 것으로 간주
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const decodedData = JSON.parse(window.atob(base64));
    return decodedData;
  } catch (error) {
    return null;
  }
};
