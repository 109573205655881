import DeleteIcon from "@mui/icons-material/Delete"; // 삭제 아이콘 추가
import { LoadingButton } from "@mui/lab";
import { Alert, Button, IconButton, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useRedirect, useUpdate } from "react-admin";
import { apiAction } from "../../apiAction";
import { priceStr } from "../../util";

export default function PriceEdit({ profile }) {
  const [durations, setDurations] = useState(profile.durations || []);
  const [error, setError] = useState(""); // 에러 상태 추가
  const redirect = useRedirect();
  const [update, { isLoading }] = useUpdate(undefined, undefined, {
    onSuccess: () => {
      redirect(`/profile/${profile.user_id}/show`);
    },
  });

  const save = async () => {
    // 유효성 검사: duration 또는 payPrice가 빈 값이거나 0인 경우 저장 불가
    const invalidDurations = durations.some(
      (d) => d.duration <= 0 || d.price_info.pay_price <= 0
    );
    if (invalidDurations) {
      setError("시간과 판매 가격은 0 또는 빈 값일 수 없습니다.");
      return;
    }

    setError(""); // 에러 초기화
    const newDurations = [];
    for (const d of durations) {
      const { data } = await apiAction("/sale/calculate", {
        pay_price: d.price_info.pay_price,
      });
      newDurations.push({
        duration: d.duration,
        price_info: data,
      });
    }
    update("profile", {
      id: profile.user_id,
      data: {
        durations: newDurations,
      },
    });
  };

  const addDuration = () => {
    setDurations([
      ...durations,
      {
        duration: "", // 빈 문자열로 초기화하여 0을 입력하지 않음
        price_info: { pay_price: "", base_price: 0 }, // 빈 문자열로 초기화
      },
    ]);
  };

  const deleteDuration = (index) => {
    setDurations(durations.filter((_, i) => i !== index));
  };

  if (!durations) return <></>;

  return (
    <>
      {durations.map((d, i) => (
        <ul key={i}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Form
              defaultDuration={d.duration}
              defaultPriceInfo={d.price_info}
              onChange={(v) => {
                setDurations([
                  ...durations.slice(0, i),
                  {
                    duration: v.duration,
                    price_info: { pay_price: v.payPrice },
                  },
                  ...durations.slice(i + 1),
                ]);
              }}
            />
            <IconButton
              aria-label="delete"
              onClick={() => deleteDuration(i)}
              style={{ marginLeft: "10px" }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </ul>
      ))}

      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        style={{ marginLeft: "40px", marginBottom: "20px" }}
      >
        <Button variant="contained" onClick={addDuration}>
          시간/가격 추가
        </Button>

        {error && (
          <Alert severity="error" style={{ marginTop: "10px" }}>
            {error}
          </Alert>
        )}

        <LoadingButton variant="contained" onClick={save} loading={isLoading}>
          저장
        </LoadingButton>
      </Stack>
    </>
  );
}

function Form({ defaultDuration, defaultPriceInfo, onChange }) {
  const [duration, setDuration] = useState(defaultDuration || ""); // 빈 문자열 처리
  const [payPrice, setPayPrice] = useState(defaultPriceInfo.pay_price || ""); // 빈 문자열 처리
  const [priceInfo, setPriceInfo] = useState(defaultPriceInfo);

  useEffect(() => {
    onChange({
      duration: duration === "" ? 0 : parseInt(duration), // 빈 문자열일 경우 0 처리
      payPrice: payPrice === "" ? 0 : parseInt(payPrice), // 빈 문자열일 경우 0 처리
    });
  }, [duration, payPrice]);

  useEffect(() => {
    if (!payPrice || isNaN(payPrice)) return;
    if (parseInt(payPrice) === priceInfo.pay_price) return;

    const timeOutId = setTimeout(
      () =>
        apiAction("/sale/calculate", {
          pay_price: parseInt(payPrice),
        }).then(({ data }) => {
          setPriceInfo(data);
        }),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [payPrice]);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <TextField
          label="시간(분)"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }} // 유효성 검사: 0 이상
        />
        <TextField
          label="판매 가격"
          value={payPrice}
          onChange={(e) => setPayPrice(e.target.value)}
          type="number"
          InputProps={{ inputProps: { min: 0 } }} // 유효성 검사: 0 이상
        />
        {priceInfo && (
          <ul>
            <li>기본 가격: {priceStr(priceInfo.base_price)}</li>
            <li>판매 가격: {priceStr(priceInfo.pay_price)}</li>
          </ul>
        )}
      </Stack>
    </>
  );
}
