// import { useEffect, useState } from "react";
// import { apiAction } from "../apiAction";
import { priceStr } from "../../util";

// function DefaultPrice() {
//   const basePrice = 31350;
//   const [price, setPrice] = useState({});
//   useEffect(() => {
//     (async () => {
//       const { data } = await apiAction(
//         `/sale/price?base_price=${basePrice}`,
//         {}
//       );
//       setPrice(data);
//     })();
//   }, [basePrice]);
//   return (
//     <ul>
//       <li>기본 가격: {priceStr(price.base_price)}</li>
//       <li>판매 가격: {priceStr(price.pay_price)}</li>
//     </ul>
//   );
// }

export default function Price({ host }) {
  if (!host.durations) return <>기본가격({priceStr(38000)})</>;
  if (host.durations.filter((d) => d.duration > 0).length === 0)
    return <>기본가격({priceStr(38000)})</>;

  return (
    <>
      {host.durations
        .filter((d) => d.duration > 0)
        .map((d, i) => (
          <ul key={i}>
            <li>시간: {d.duration}분</li>
            <li>기본 가격: {priceStr(d.price_info.base_price)}</li>
            <li>판매 가격: {priceStr(d.price_info.pay_price)}</li>
          </ul>
        ))}
    </>
  );
}
