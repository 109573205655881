import { useState } from "react";
import {
  Button as RaButton,
  Confirm,
  useCreate,
  useGetList,
  useUpdate,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { gql } from "@apollo/client";
import { apolloClient } from "../../lib/apollo";

function useCurrentUserId() {
  const currentUserEmail = getAuth(getApp()).currentUser?.email;
  const { data: userData } = useGetList("user", {
    filter: {
      email: currentUserEmail,
    },
  });
  const currentUser = (userData ?? [{}])[0];
  return currentUser.id;
}

function useThread(guestUserId) {
  const currentUserId = useCurrentUserId();
  const { data: threadData } = useGetList("thread", {
    filter: {
      host_user_id: currentUserId,
      guest_user_id: guestUserId,
    },
  });

  const thread = (threadData ?? [{}])[0];
  return thread;
}

function SendMessageToGuest({ guestUserId, onSuccess }) {
  const thread = useThread(guestUserId);
  return (
    <SendMessage
      thread={thread}
      guestUserId={guestUserId}
      onSuccess={onSuccess}
    />
  );
}

function SendMessage({ thread, guestUserId, onSuccess }) {
  const currentUserId = useCurrentUserId();
  const [text, setText] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [update] = useUpdate();
  const [createMessage, { isLoading }] = useCreate();

  const sendMessage = (threadId) => {
    createMessage(
      "message",
      {
        data: {
          thread_id: threadId,
          user_id: currentUserId,
          content_type: "admin",
          content: { body: text },
        },
      },
      {
        onSuccess: (data) => {
          update("thread", {
            id: threadId,
            data: { last_message_id: data.id },
          });
          if (onSuccess) onSuccess();
          setOpenConfirm(false);
        },
      }
    );
  };

  return (
    <>
      <DialogTitle>메세지 보내기</DialogTitle>
      <DialogContent sx={{ height: "300px" }}>
        <TextField
          value={text}
          onChange={(e) => setText(e.target.value)}
          multiline={true}
          rows={10}
          sx={{ width: "300px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirm(true)}>Send</Button>
      </DialogActions>
      <Confirm
        isOpen={openConfirm}
        loading={isLoading}
        title="메세지 보내기"
        content="정말 메세지 보낼꺼에요? 취소 못함!!"
        onConfirm={async () => {
          if (thread?.id) {
            sendMessage(thread.id);
          } else {
            const { data } = await apolloClient.mutate({
              mutation: INSERT_THREAD,
              variables: {
                host_user_id: currentUserId,
                guest_user_id: guestUserId,
              },
            });
            sendMessage(data.insert_thread_one.id);
          }
        }}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  );
}

export default function SendMessageDialog({ thread, guestUserId, onSuccess }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="메세지 보내기" />
      <Dialog open={open} onClose={() => setOpen(false)}>
        {thread ? (
          <SendMessage
            thread={thread}
            onSuccess={() => {
              if (onSuccess) onSuccess();
              setOpen(false);
            }}
          />
        ) : (
          <SendMessageToGuest
            guestUserId={guestUserId}
            onSuccess={() => {
              if (onSuccess) onSuccess();
              setOpen(false);
            }}
          />
        )}
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Dialog>
    </>
  );
}

export const INSERT_THREAD = gql`
  mutation InsertThreadOne($host_user_id: bigint!, $guest_user_id: bigint!) {
    insert_thread_one(
      object: { host_user_id: $host_user_id, guest_user_id: $guest_user_id }
      on_conflict: { constraint: thread_host_user_id_guest_user_id_key }
    ) {
      id
    }
  }
`;
