export function priceStr(price, currency) {
  const currencyMark = currency === "USD" ? "$" : "₩";
  return `${currencyMark}${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
}

export function trim(str, len = 20) {
  if (!str) return null;
  if (str.length > len) return str.substring(0, len) + "...";
  return str;
}
