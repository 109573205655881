import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useGetList, Button as RaButton, Confirm } from "react-admin";
import { apiAction } from "../apiAction";

export default function ChangeMeetingDialog({ order, refresh }) {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [targetId, setTargetId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { data } = useGetList("meeting", {
    pagination: { page: 1, perPage: 500 },
    sort: { field: "start_at", order: "ASC" },
    filter: {
      host_id: order?.host_id,
      status: "created",
    },
  });

  const availableMeetings = (data ?? [])
    .filter((m) => dayjs(m.start_at).isAfter(dayjs()))
    .sort((a, b) => (dayjs(a.start_at).isBefore(dayjs(b.start_at)) ? -1 : 1));
  console.log("availableMeetings:", availableMeetings);

  async function move() {
    setLoading(true);
    const res = await apiAction("/meeting/move", {
      src: order.meeting_id,
      target: targetId,
    });

    if (res.success === false) {
      alert(res.error.message);
    } else {
      refresh();
    }
    setLoading(false);
    setOpenConfirm(false);
    setOpen(false);
  }

  if (!order.meeting_id) return <></>;

  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="만남시간변경" />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>현재 예정된 만남 시간</DialogTitle>
        <DialogContent sx={{ height: "150px" }}>
          {dayjs(order.meeting_start_at).format("YYYY.MM.DD A hh:mm")}
        </DialogContent>
        <DialogTitle>변경 가능한 만남 시간</DialogTitle>
        <DialogContent sx={{ height: "150px" }}>
          {availableMeetings.map((m) => (
            <div key={m.id}>
              {dayjs(m.start_at).format("YYYY.MM.DD A hh:mm")}
              <Button
                onClick={() => {
                  setTargetId(m.id);
                  setOpenConfirm(true);
                }}
              >
                선택
              </Button>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
        <Confirm
          isOpen={openConfirm}
          loading={loading}
          title="만남 시간 변경"
          content="정말 변경 할꺼에요? 취소 못함!!"
          onConfirm={() => move()}
          onClose={() => setOpenConfirm(false)}
        />
      </Dialog>
    </>
  );
}
