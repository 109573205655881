import { Box, Chip, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListButton,
  ReferenceField,
  ReferenceManyField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { apiAction } from "../apiAction";
import { ChattingBox } from "../components/meeting/ChattingBox";
import RescheduleDialog from "../components/meeting/RescheduleDialog";
import { isAdmin } from "../lib/auth";

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: "0.5rem",
  },
}));
const StatusFilter = ({ label }) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={label} />;
};

const filters = [
  <TextInput source="profile_name" label="호스트 이름" alwaysOn />,
  <TextInput source="guest_name" label="게스트 이름" alwaysOn />,
  <StatusFilter source="status" label="reserved" defaultValue={"reserved"} />,
];

export const MeetingList = (props) => (
  <List filters={filters} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField
        label="호스트"
        source="host_id"
        reference="profile"
        link="show"
      >
        <TextField label="이름" source="name" />
      </ReferenceField>
      <ReferenceField
        label="게스트"
        source="guest_id"
        reference="user"
        link="show"
      >
        <TextField label="이름" source="display_name" />
      </ReferenceField>
      <FunctionField
        label="소속"
        render={(record) => {
          if (!record.guest_id) return <></>;
          return (
            <div style={{ minWidth: "90px", maxWidth: "75px" }}>
              <ReferenceManyField
                reference="organization_user"
                source="guest_id"
                target="user_id"
              >
                <SingleFieldList>
                  <ReferenceField
                    source="organization_id"
                    reference="organization"
                    link="show"
                  >
                    <TextField label="이름" source="name" />
                  </ReferenceField>
                </SingleFieldList>
              </ReferenceManyField>
            </div>
          );
        }}
      />
      <TextField source="status" />
      <DateField source="start_at" label="대화 시작 시간" showTime />
      <DateField source="end_at" label="대화 종료 시간" showTime />
      <DateField source="host_entered_at" label="호스트 입장 시간" showTime />
      <DateField source="guest_entered_at" label="게스트 입장 시간" showTime />
      <FunctionField
        label="agora url"
        render={(record) => {
          if (!record.agora_meeting_url) return <></>;
          return record.agora_meeting_url.substring(27, 35) + "...";
        }}
      />
    </Datagrid>
  </List>
);

const MeetingTitle = ({ record }) => {
  return <span>Meeting: {record ? `${record.name}` : ""}</span>;
};

const MeetingShowActions = ({ data }) => {
  const record = useRecordContext();

  return (
    <TopToolbar>
      {record && isAdmin() && <RescheduleDialog meeting={record} />}
      <ListButton record={data} label="Back" />
    </TopToolbar>
  );
};
function getIdFromUrl() {
  const url = window.location.href;
  const splittedUrl = url.split("/");
  const id = splittedUrl[5];
  return id;
}

function parseBytesToJson(bytes) {
  const decodedBytes = atob(bytes);
  let byteArray = new Uint8Array(decodedBytes.length);

  for (let i = 0; i < decodedBytes.length; i++) {
    byteArray[i] = decodedBytes.charCodeAt(i);
  }

  const decoder = new TextDecoder("utf-8");
  const decodedString = decoder.decode(byteArray);

  const jsonObjectArray = JSON.parse(decodedString);
  return jsonObjectArray.flat();
}

async function getJsonDialogById(id) {
  const { data } = await apiAction(`/meeting/dialog?meetingId=${id}`);

  const jsonData = [];

  data.forEach((bytes) => {
    jsonData.push(...parseBytesToJson(bytes));
  });

  return jsonData;
}

export const MeetingShow = (props) => {
  const [jsonData, setJsonData] = useState(null);
  const meetingId = getIdFromUrl();

  useEffect(() => {
    async function fetchData() {
      const data = await getJsonDialogById(meetingId);
      setJsonData(data);
    }
    fetchData();
  }, [meetingId]);

  return (
    <Show
      title={<MeetingTitle />}
      actions={<MeetingShowActions />}
      {...props}
      sx={{ marginBottom: "3vh" }}
    >
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <ReferenceField
            label="호스트"
            source="host_id"
            reference="profile"
            link="show"
          >
            <TextField label="이름" source="name" />
          </ReferenceField>
          <ReferenceField
            label="게스트"
            source="guest_id"
            reference="user"
            link="show"
          >
            <TextField label="이름" source="display_name" />
          </ReferenceField>
          <TextField source="status" />
          <DateField source="start_at" label="대화 시작 시간" showTime />
          <DateField source="end_at" label="대화 종료 시간" showTime />
          <DateField
            source="host_entered_at"
            label="호스트 입장 시간"
            showTime
          />
          <DateField
            source="guest_entered_at"
            label="게스트 입장 시간"
            showTime
          />
          {/* <TextField source="agora_meeting_url" /> */}
          <FunctionField
            label="agora meeting url"
            render={(record) => {
              if (!record.agora_meeting_url)
                return (
                  <Button
                    onClick={async () => {
                      const res = await apiAction(
                        "/admin/meeting/create-zoom-meeting",
                        {
                          meetingId: record.id,
                        }
                      );
                      if (res.success === false) {
                        alert("미팅 생성에 실패했습니다.");
                        return;
                      }
                    }}
                  >
                    미팅생성
                  </Button>
                );
              return record.agora_meeting_url;
            }}
          />
          {jsonData ? (
            <Paper>
              <Box sx={{ padding: "1em", fontWeight: "bold" }}>대화 내용</Box>
              {jsonData.map((item, index) => {
                const isSameSpeaker =
                  jsonData[index - 1]?.speaker.name === item.speaker.name;
                return (
                  <Box
                    key={index}
                    sx={{
                      padding: isSameSpeaker ? "2px 2.2em" : "2px 1em",
                      backgroundColor:
                        item.speaker.name === "A" ? "" : "#E9E9E9",
                      marginTop: isSameSpeaker ? "0" : "6px",
                    }}
                  >
                    {isSameSpeaker ? "" : `${item.speaker.name}: `}
                    {item.textEdited}
                  </Box>
                );
              })}
            </Paper>
          ) : (
            <></>
          )}
        </Tab>
        <Tab label="Chat GPT">
          <ChattingBox meetingId={meetingId} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
