import { useGetOne } from "react-admin";
import { isAdmin } from "../../lib/auth";

export default function HostInfo({ userId }) {
  const { data } = useGetOne("user", { id: userId });

  if (!isAdmin()) return <></>;
  if (!data) return <></>;

  if (data.email === data.email2) return <span>이메일: {data?.email}</span>;
  if (!data.email2) return <span>이메일: {data?.email}</span>;
  return <span>이메일: {data?.email2}</span>;
}
