import { useState } from "react";
import {
  Button as RaButton,
  Confirm,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { apiAction } from "../../apiAction";

function ForceComplete({ orderId, startAt: initStartAt, endAt: initEndAt, onSuccess }) {
  const [startAt, setStartAt] = useState(initStartAt);
  const [endAt, setEndAt] = useState(initEndAt);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const confirm = async () => {
    setIsLoading(true);
    const { success } = await apiAction("/order/forceComplete", {
      orderId: orderId,
      startAt: startAt,
      endAt: endAt,
    })
    setIsLoading(false);
    if (success) {
      onSuccess();
    }
  };

  return (
    <>
      <DialogTitle>강제 확정 처리</DialogTitle>
      <DialogContent>
        <p>
          Start at:<br />
          <TextField
            value={startAt}
            onChange={(e) => setStartAt(e.target.value)}
            style={{ width: "300px" }}
          />
        </p>
        <p>
          End at:<br />
          <TextField
            value={endAt}
            onChange={(e) => setEndAt(e.target.value)}
            style={{ width: "300px" }}
          />
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenConfirm(true)}>확정</Button>
      </DialogActions>
      <Confirm
        isOpen={openConfirm}
        loading={isLoading}
        title="강제 확정 처리"
        content="정말 확정 처리 할까요? 취소 못함!!"
        onConfirm={confirm}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  );
}

export default function ForceCompleteDialog({ order }) {
  const [open, setOpen] = useState(false);
  const startAt = order.request_meeting_times.length > 0 ? order.request_meeting_times[0].start_at : dayjs().format("YYYY-MM-DDTHH:mm");
  const endAt = order.request_meeting_times.length > 0 ? order.request_meeting_times[0].end_at : dayjs().format("YYYY-MM-DDTHH:mm");
  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="강제 확정 처리" />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <ForceComplete orderId={order.id} startAt={startAt} endAt={endAt} onSuccess={() => setOpen(false)} />
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Dialog>
    </>
  );
}