import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Chip, CircularProgress, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { gql } from "@apollo/client";
import { ConfirmProvider, useConfirm } from "material-ui-confirm";
import { apolloClient } from "../lib/apollo";
import { useGetList } from "react-admin";

const filter = createFilterOptions();
export default function Provider({ profile }) {
  return (
    <ConfirmProvider>
      <br />
      카테고리:
      <UserTagEdit profile={profile} type="category" />
      <br />
      해시태그:
      <UserTagEdit profile={profile} type="hashtag" />
    </ConfirmProvider>
  );
}
function UserTagEdit({ profile, type }) {
  const confirm = useConfirm();
  const [selecting, setSelecting] = useState();
  const [deleting, setDeleting] = useState();
  const { data: userTags, refetch } = useGetList("user_tag_view", {
    pagination: { page: 1, perPage: 500 },
    filter: {
      user_id: profile.user_id,
      is_category: type === "category",
    },
  });
  const { data: allTags } = useGetList("tag", {
    pagination: { page: 1, perPage: 500 },
    filter: {
      // is_banner: false,
      is_category: type === "category",
    },
  });

  const selectTag = async (tag) => {
    setSelecting(true);
    if (tag?.id === undefined && tag?.name !== undefined) {
      const { data } = await apolloClient.mutate({
        mutation: INSERT_TAG,
        variables: { tag_name: tag.name },
      });

      const insertedTag = data.insert_tag_one;
      if (insertedTag.id !== undefined) selectTag(insertedTag);
    }

    if (tag?.id) {
      await apolloClient.mutate({
        mutation: INSERT_USER_TAG,
        variables: { user_id: profile.user_id, tag_id: tag.id },
      });
      refetch();
    }
    setSelecting(false);
  };

  const removeTag = async (tagId) => {
    await apolloClient.mutate({
      mutation: DELETE_USER_TAG,
      variables: { user_id: profile.user_id, tag_id: tagId },
    });
    refetch();
  };

  const getType = (name) => {
    return (allTags ?? []).find((t) => t.name === name)?.type;
  };

  return (
    <>
      <AutocompleteBox>
        <Autocomplete
          disabled={selecting}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              selectTag({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              selectTag({
                name: newValue.inputValue,
              });
            } else {
              selectTag(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params).sort((a, b) => {
              if (a.type === b.type) return 0;
              if (a.type === "career") return -1;
              if (a.type === "life") return 1;
              return 0;
            });
            console.log(filtered);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `"${inputValue}" 추가하기`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={(allTags ?? []).filter(
            (t) => !(userTags ?? []).map((ut) => ut.tag_id).includes(t.id)
          )}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.name, inputValue);
            const parts = parse(option.name, matches);
            return (
              <li {...props}>
                <div>
                  {parts.map((part, index) => {
                    const type = getType(part.text);
                    return (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                          color:
                            type === "career"
                              ? "#FF4C46"
                              : type === "life"
                              ? "#00C3A3"
                              : undefined,
                        }}
                      >
                        {part.text}
                        {type && ` (${type})`}
                      </span>
                    );
                  })}
                </div>
              </li>
            );
          }}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => <TextField {...params} />}
        />
        {selecting && (
          <Box sx={{ mt: 2, ml: -4 }}>
            <CircularProgress size="2rem" />
          </Box>
        )}
      </AutocompleteBox>

      {(userTags ?? []).map((ut) => {
        const type = getType(ut.tag_name);
        return (
          <StyledChip
            key={ut.tag_id}
            sx={{ m: 0.5 }}
            label={ut.tag_name}
            disabled={deleting !== undefined}
            variant="outlined"
            className={type}
            deleteIcon={
              deleting === ut.tag_id ? (
                <CircularProgress size="1.5rem" />
              ) : (
                undefined
              )
            }
            onDelete={(e) => {
              e.preventDefault();
              confirm({
                title: ut.tag_name,
                description: "정말 삭제하겠습니까?",
              })
                .then(async () => {
                  setDeleting(ut.tag_id);
                  await removeTag(ut.tag_id);
                  setDeleting(undefined);
                })
                .catch(() => {});
            }}
          />
        );
      })}
    </>
  );
}
const AutocompleteBox = styled(Box)`
  display: flex;
  margin-bottom: 1rem;
`;
const StyledChip = styled(Chip)`
  span {
    color: black;
  }
  &.career {
    border-color: #ff4c46;
  }
  &.life {
    border-color: #00c3a3;
  }
`;

const INSERT_TAG = gql`
  mutation InsertTag($tag_name: String!) {
    insert_tag_one(
      object: { name: $tag_name }
      on_conflict: { constraint: tag_name_key, update_columns: [name] }
    ) {
      id
      name
      is_search_keyword
      is_banner
    }
  }
`;

export const INSERT_USER_TAG = gql`
  mutation InsertUserTag($user_id: bigint!, $tag_id: bigint!) {
    insert_user_tag_one(
      object: { user_id: $user_id, tag_id: $tag_id }
      on_conflict: { constraint: user_tag_user_id_tag_id_key }
    ) {
      id
      tag {
        id
        name
        is_search_keyword
        is_banner
      }
    }
  }
`;

export const DELETE_USER_TAG = gql`
  mutation DeleteUserTag($user_id: bigint!, $tag_id: bigint!) {
    delete_user_tag(
      where: { user_id: { _eq: $user_id }, tag_id: { _eq: $tag_id } }
    ) {
      returning {
        id
      }
    }
  }
`;
