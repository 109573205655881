import { useState } from "react";
import { Button as RaButton, useCreate } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchProfile from "../profile/SearchProfile";
import { priceStr } from "../../util";

export default function AddHostDialog({ organization, refresh }) {
  const [open, setOpen] = useState(false);
  const [host, setHost] = useState();

  const [searchText, setSearchText] = useState("");
  const [hostName, setHostName] = useState("");

  const [create, { loading }] = useCreate();

  const handleAdd = async () => {
    await create(
      "organization_host",
      {
        data: {
          organization_id: organization.id,
          host_id: host.user_id,
        },
      },
      { onSuccess: () => refresh() }
    );
    setOpen(false);
  };

  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="호스트 추가" />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>호스트 추가</DialogTitle>
        <DialogContent>
          <Stack flexDirection="row">
            <TextField
              label="호스트 이름"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setHostName(searchText);
                }
              }}
            />
            <Button onClick={() => setHostName(searchText)}>조회</Button>
          </Stack>
          {hostName && (
            <SearchProfile name={hostName} onSelect={(h) => setHost(h)} />
          )}
          {host && (
            <p>
              선택한 호스트: {host.name}{" "}
              {(host.durations ?? [])
                .map(
                  (d) => `${priceStr(d.price_info.pay_price)}(${d.duration}분)`
                )
                .join(", ")}
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} disabled={loading}>
            Close
          </Button>
          <LoadingButton onClick={handleAdd} loading={loading}>
            추가
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
