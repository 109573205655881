import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import { Button as RaButton, useRedirect } from "react-admin";
import { apiAction } from "../../apiAction";
import { LoadingButton } from "@mui/lab";

export default function RescheduleDialog({ meeting }) {
  const redirect = useRedirect();
  const duration = dayjs(meeting?.end_at).diff(
    dayjs(meeting?.start_at),
    "minute"
  );
  const [open, setOpen] = useState(false);
  const [startAt, setStartAt] = useState(dayjs(meeting?.start_at));
  const [loading, setLoading] = useState(false);
  const endAt = startAt.add(duration, "minute");
  const reschedule = async () => {
    setLoading(true);
    const { data, success, error } = await apiAction("/meeting/reschedule", {
      srcMeetingId: meeting?.id,
      orderId: meeting?.order_id,
      startAt: startAt,
      endAt: endAt,
    });
    setLoading(false);
    if (!success) {
      alert(error?.message ?? "변경에 실패했습니다.");
    } else {
      setOpen(false);
      redirect(`/meeting_status/${data.meeting_id}/show`);
    }
  };
  return (
    <>
      <RaButton label="시간변경" onClick={() => setOpen(true)} />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          대화 시간 변경하기
          <IconButton onClick={() => setOpen(false)} style={{ float: "right" }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker value={startAt} onChange={(d) => setStartAt(d)} />
          </LocalizationProvider>
          <p>
            {startAt.format("M월 D일 HH:mm")} ~ {endAt.format("HH:mm")} (
            {duration}분)
          </p>
          <p>시간대: {Intl.DateTimeFormat().resolvedOptions().timeZone}</p>
        </DialogContent>
        <Divider />
        <DialogActions>
          정말 변경하시겠습니까?
          <Button onClick={() => setOpen(false)}>취소</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={reschedule}
          >
            변경
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
