import { useEffect, useState } from "react";
import { apiAction } from "../../apiAction";

export default function PageViewCount({ userId }) {
  const [viewCount, setViewCount] = useState();
  useEffect(() => {
    apiAction("/action/pageview/get?userId=" + userId).then(({ data }) =>
      setViewCount(data)
    );
  }, [userId]);
  return <>{viewCount}</>;
}
