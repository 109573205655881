import { gql } from "@apollo/client";
import { Divider } from "@mui/material";
import { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  CheckboxGroupInput,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ListButton,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceManyField,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
} from "react-admin";
import { CSVLink } from "react-csv";
import ImageUpload from "../components/ImageUpload";
import SlugForm from "../components/SlugForm";
import UserTagEdit from "../components/UserTagEdit";
import VideoPlayer from "../components/VideoPlayer";
import ZoomButton from "../components/ZoomButton";
import CoachingInfoEdit from "../components/profile/CoachingInfoEdit";
import ExperienceEdit from "../components/profile/ExperienceEdit";
import HostInfo from "../components/profile/HostInfo";
import PageViewCount from "../components/profile/PageViewCount";
import PointEdit from "../components/profile/PointEdit";
import Price from "../components/profile/Price";
import PriceEdit from "../components/profile/PriceEdit";
import { apolloClient } from "../lib/apollo";
import { isAdmin } from "../lib/auth";
import { priceStr, trim } from "../util";

const ProfileListActions = () => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState(undefined);
  return (
    <TopToolbar>
      {csvData && (
        <CSVLink filename={"hosts.csv"} data={csvData}>
          CSV 파일 다운로드
        </CSVLink>
      )}
      {!csvData && (
        <Button
          label={loading ? "변환중..." : "호스트 이메일 주소 내려받기"}
          onClick={async (e) => {
            e.preventDefault();
            setLoading(true);
            const { data } = await apolloClient.query({
              query: QUERY_HOST_EMAILS,
            });
            const emails = data.profile.map((h) => {
              if (!h.user.email2 || h.user.email2.length === 0) {
                return [h.name, h.user.email];
              }
              return [h.name, h.user.email2];
            });

            setCsvData([["호스트 이름", "이메일"], ...emails]);
            setLoading(false);
          }}
        />
      )}
    </TopToolbar>
  );
};

const filters = [
  <TextInput label="호스트 이름" source="name" alwaysOn />,
  <BooleanInput label="공개" source="is_published" alwaysOn />,
  // <CheckboxGroupInput
  //   source="is_banner"
  //   label="메인노출"
  //   choices={[{ id: "true", name: "true" }]}
  //   alwaysOn
  // />,
  // <CheckboxGroupInput
  //   source="is_career"
  //   label="커리어"
  //   choices={[{ id: "true", name: "true" }]}
  //   alwaysOn
  // />,
  // <CheckboxGroupInput
  //   source="is_life"
  //   label="라이프"
  //   choices={[{ id: "true", name: "true" }]}
  //   alwaysOn
  // />,
  <CheckboxGroupInput
    source="status"
    label="프로필 상태"
    choices={[
      { id: "A", name: "A" },
      { id: "B", name: "B" },
      { id: "C", name: "C" },
    ]}
    alwaysOn
  />,
  // <TextInput label="큐레이터 노트" source="note" alwaysOn />,
];

export const ProfileList = (props) => (
  <List filters={filters} actions={<ProfileListActions />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <NumberField source="user_id" />
      <NumberField source="rank" />
      <TextField source="slug" label="URL key" />
      <TextField source="name" label="이름" />
      <FunctionField
        label="기본 가격"
        render={(record) => {
          if (!record.durations) return <>기본가격({priceStr(38000)})</>;

          const durations = record.durations.filter((d) => d.duration > 0);

          if (durations.length === 0) return <>기본가격({priceStr(38000)})</>;

          return trim(
            durations
              .map(
                (d) => `${priceStr(d.price_info.base_price)}(${d.duration}분)`
              )
              .join(", "),
            25
          );
        }}
      />
      <TextField source="title" label="제목" />
      {/* <BooleanField source="is_banner" label="메인노출" />
      <BooleanField source="is_career" label="커리어" />
      <BooleanField source="is_life" label="라이프" />
      <TextField source="status" label="프로필 상태" />
      <TextField source="note" label="큐레이터 노트" /> */}
      <BooleanField source="is_published" label="공개" />
      <BooleanField source="is_coach" label="코치" />
      <ReferenceField
        label="줌 계정"
        source="user_id"
        reference="user"
        link={false}
      >
        <FunctionField
          render={(record) => `${record.zoom_email} (${record.zoom_status})`}
        />
      </ReferenceField>
      <BooleanField source="agreement_host_guideline" label="동의" />
      <DateField source="updated_at" label="수정날짜" />
    </Datagrid>
  </List>
);

const ProfileTitle = () => {
  const record = useRecordContext();
  return <span>Host: {record?.name}</span>;
};

const ProfileShowActions = () => {
  const record = useRecordContext();
  if (!isAdmin()) return <></>;
  return (
    <TopToolbar>
      <div style={{ marginRight: 10 }}>
        <ZoomButton host_id={record?.id} zoom_status={record?.zoom_status} />
      </div>
      <ListButton record={record} />
      <EditButton record={record} />
    </TopToolbar>
  );
};

export const ProfileShow = (props) => {
  return (
    <Show title={<ProfileTitle />} actions={<ProfileShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="프로필">
          <NumberField source="user_id" />x
          <FunctionField render={(record) => <SlugForm profile={record} />} />
          <TextField source="name" label="이름" />
          <ReferenceField source="user_id" reference="user" link="show">
            <TextField source="display_name" />
          </ReferenceField>
          <FunctionField
            label="페이지 뷰 카운트"
            render={(record) => <PageViewCount userId={record.user_id} />}
          />
          <FunctionField
            render={(record) => <HostInfo userId={record.user_id} />}
          />
          <TextField source="title" label="제목" />
          <FunctionField
            label="가격"
            render={(record) => <Price host={record} />}
          />
          <FunctionField
            label="Introduction"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    record.introduction?.replace(/(?:\r\n|\r|\n)/g, "<br>") ??
                    "",
                }}
              ></div>
            )}
          />
          <TextField label="도시, 국가" source="location" />
          <TextField label="회사이름" source="company" />
          <TextField label="직책" source="position" />
          <TextField label="부서" source="team" />
          <TextField source="linkedin_url" />
          <FunctionField
            label="동영상"
            render={(record) => {
              if (!record.video) return <></>;
              if (!record.video.mux_asset) return <></>;
              if (!record.video.mux_asset.playback_ids) return <></>;
              if (record.video.mux_asset.playback_ids.length === 0)
                return <></>;
              return (
                <>
                  <VideoPlayer
                    playbackId={record.video.mux_asset.playback_ids[0].id}
                  />
                  <a
                    href={record.video.original}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    원본 영상
                  </a>
                </>
              );
            }}
          />
          <ImageField source="main_image" label="메인 이미지" />
          <FunctionField
            label="images"
            render={(record) =>
              (record.images ?? []).map((image, i) => (
                <img
                  key={i}
                  alt="호스트 이미지"
                  src={image}
                  width="120"
                  height="160"
                  style={{ margin: 5, border: "10 solid green" }}
                />
              ))
            }
          />
          <ReferenceManyField
            reference="point"
            target="user_id"
            label="나눌 수 있는 이야기"
            sort={{ field: "seq", order: "asc" }}
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="type" />
              <TextField source="title" label="내용" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            reference="experience"
            target="user_id"
            label="이력"
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <TextField source="category" label="카테고리" />
              <TextField source="start" />
              <TextField source="end" />
              <TextField source="title" label="내용" />
              <TextField source="desc" />
            </Datagrid>
          </ReferenceManyField>
          <DateField label="생성날짜" source="created_at" />
          <DateField label="수정날짜" source="updated_at" />
          <ReferenceManyField
            label="카테고리: career"
            reference="user_tag_view"
            target="user_id"
            filter={{
              is_category: true,
              tag_type: "career",
            }}
          >
            <SingleFieldList>
              <FunctionField
                render={(record) => (
                  <ul>
                    <li>{record.tag_name}</li>
                    {/* <li>타입: {record.tag_type}</li>
                    <li>카테고리: {record.is_category.toString()}</li> */}
                  </ul>
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            label="카테고리: life"
            reference="user_tag_view"
            target="user_id"
            filter={{
              is_category: true,
              tag_type: "life",
            }}
          >
            <SingleFieldList>
              <FunctionField
                render={(record) => (
                  <ul>
                    <li>{record.tag_name}</li>
                    {/* <li>타입: {record.tag_type}</li>
                    <li>카테고리: {record.is_category.toString()}</li> */}
                  </ul>
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            label="해시태그"
            reference="user_tag_view"
            target="user_id"
            filter={{
              is_category: false,
            }}
          >
            <SingleFieldList>
              <FunctionField
                render={(record) => (
                  <ul>
                    <li>
                      {record.tag_name}({record.tag_type})
                    </li>
                  </ul>
                )}
              />
            </SingleFieldList>
          </ReferenceManyField>
        </Tab>
        <Tab label="코칭" path="coaching">
          <FunctionField
            label="코칭상품 설명"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    record.coaching_info?.description?.replace(
                      /(?:\r\n|\r|\n)/g,
                      "<br>"
                    ) ?? "",
                }}
              ></div>
            )}
          />
          <ReferenceManyField
            reference="product"
            target="user_id"
            label="코칭상품"
            sort={{ field: "seq", order: "asc" }}
          >
            <Datagrid rowClick="show" bulkActionButtons={false}>
              <FunctionField
                label="제목"
                render={(record) => (
                  <div style={{ maxWidth: 200 }}>{record.title}</div>
                )}
              />
              <FunctionField
                label="설명"
                render={(record) => (
                  <div style={{ maxWidth: 400 }}>{record.description}</div>
                )}
              />
              <FunctionField
                label="과정"
                render={(record) => (
                  <ul>
                    {record.steps.map((step, i) => (
                      <li key={i}>
                        {step.name}
                        <br />
                        {step.desc}
                        <br />- 사전과정:{step.pre_step?.name}{" "}
                        {step.pre_step?.desc}
                        <br />- 활동자료:{step.post_step?.name}{" "}
                        {step.post_step?.desc}
                      </li>
                    ))}
                  </ul>
                )}
              />
              <TextField source="base_price" label="기본가격" />
              <TextField source="pay_price" label="판매가격" />
              <NumberField source="duration" label="시간(분)" />
            </Datagrid>
          </ReferenceManyField>

          <div style={{ margin: 20 }}>
            --------- 아래는 기획전 내용 -----------
          </div>
          <FunctionField
            label="color"
            render={(record) => (
              <ul>
                <li>{record.coaching_info?.color1}</li>
                <li>{record.coaching_info?.color2}</li>
                <li>{record.coaching_info?.color3}</li>
              </ul>
            )}
          />
          <FunctionField
            label="이름"
            render={(record) => record.coaching_info?.name}
          />
          <FunctionField
            label="nickname"
            render={(record) => record.coaching_info?.nickname}
          />
          <FunctionField
            label="job1"
            render={(record) => record.coaching_info?.job1}
          />
          <FunctionField
            label="job2"
            render={(record) => record.coaching_info?.job2}
          />
          <FunctionField
            label="title"
            render={(record) => record.coaching_info?.title}
          />
          <FunctionField
            label="subtitle"
            render={(record) => record.coaching_info?.subtitle}
          />
          <FunctionField
            label="coaching_technique"
            render={(record) => record.coaching_info?.coaching_technique}
          />
          <FunctionField
            label="coaching_technique_desc1"
            render={(record) => record.coaching_info?.coaching_technique_desc1}
          />
          <FunctionField
            label="coaching_technique_desc2"
            render={(record) => record.coaching_info?.coaching_technique_desc2}
          />

          <FunctionField
            label="이런 분에게 필요해요"
            render={(record) => (
              <ul>
                {(record.coaching_info?.points ?? []).map((p, i) => (
                  <li key={i}>{p.value}</li>
                ))}
              </ul>
            )}
          />
          <FunctionField
            label="커리큘럼"
            render={(record) => (
              <>
                {record.coaching_info?.curriculum?.pre_step && (
                  <p>
                    {record.coaching_info?.curriculum?.pre_step.name}
                    <br />
                    {record.coaching_info?.curriculum?.pre_step.desc}
                    <br />
                    {record.coaching_info?.curriculum?.pre_step.benefit}
                  </p>
                )}
                {(record.coaching_info?.curriculum?.steps ?? []).map(
                  (step, i) => (
                    <p key={i}>
                      {i + 1}회차: {step.name}
                      <br />
                      {step.desc}
                      <br />
                      {step.benefit}
                    </p>
                  )
                )}
                {record.coaching_info?.curriculum?.post_step && (
                  <p>
                    추가: {record.coaching_info?.curriculum?.post_step.name}
                    <br />
                    {record.coaching_info?.curriculum?.post_step.desc}
                    <br />
                    {record.coaching_info?.curriculum?.post_step.benefit}
                  </p>
                )}
              </>
            )}
          />
          <FunctionField
            label="회차/기간"
            render={(record) => record.coaching_info?.schedule}
          />
          <FunctionField
            label="코칭 기간"
            render={(record) => record.coaching_info?.term}
          />
          <FunctionField
            label="offer.sup"
            render={(record) => record.coaching_info?.offer?.sup}
          />
          <FunctionField
            label="offer.title"
            render={(record) => record.coaching_info?.offer?.title}
          />
          <FunctionField
            label="offer.p"
            render={(record) => record.coaching_info?.offer?.p}
          />
        </Tab>
        <Tab label="큐레이팅" path="curating">
          {/* <BooleanField source="is_banner" label="메인노출" /> */}
          <BooleanField source="is_published" label="공개" />
          <BooleanField source="is_coach" label="코치" />
          <NumberField source="rank" />
          <FunctionField
            label="bio"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html: record.bio?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
                }}
              ></div>
            )}
          />

          <TextField source="status" label="프로필 상태" />
          <FunctionField
            label="큐레이터 노트"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html: record.note?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
                }}
              ></div>
            )}
          />

          <BooleanField source="agreement_host_guideline" label="동의" />
          <ReferenceField
            label="줌 계정"
            source="user_id"
            reference="user"
            link={false}
          >
            <FunctionField
              render={(record) =>
                `${record.zoom_email} (${record.zoom_status})`
              }
            />
          </ReferenceField>

          <BooleanField
            label="호스트 가이드라인 동의"
            source="agreement_host_guideline"
          />
        </Tab>
        <Tab label="후기" path="review">
          <ReferenceManyField
            reference="review"
            target="host_id"
            addLabel={false}
          >
            <Datagrid rowClick="show">
              <TextField source="user_id" />
              <TextField source="score" />
              <TextField source="content" />
              <DateField source="created_at" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ProfileEdit = (props) => (
  <Edit {...props} redirect="edit">
    <TabbedShowLayout>
      <Tab label="프로필">
        <SimpleForm>
          <TextInput source="name" />
          <TextInput source="position" />
          <TextInput source="title" style={{ width: "700px" }} />
          <TextInput source="company" />
          <TextInput source="location" />
          <TextInput
            source="introduction"
            multiline
            fullWidth
            style={{ width: "700px" }}
          />
        </SimpleForm>

        <Divider sx={{ m: 5 }} />
        <FunctionField
          label="가격"
          render={(record) => <PriceEdit profile={record} />}
        />
        <FunctionField
          label="호스팅분야"
          render={(record) => <UserTagEdit profile={record} />}
        />
        <FunctionField
          label="사진"
          render={(record) => <ImageUpload profile={record} />}
        />
        <FunctionField
          label="나눌 수 있는 이야기"
          render={(record) => <PointEdit profile={record} />}
        />
        <FunctionField
          label="경력/교육/미디어/수상/등등"
          render={(record) => <ExperienceEdit profile={record} />}
        />
      </Tab>
      <Tab label="코칭" path="coaching">
        <FunctionField
          label="코칭내용"
          render={(record) => <CoachingInfoEdit profile={record} />}
        />
      </Tab>
      <Tab label="큐레이팅">
        <SimpleForm>
          {/* <BooleanInput source="is_banner" label="메인노출" />
          <BooleanInput source="is_career" label="커리어" />
          <BooleanInput source="is_life" label="라이프" /> */}
          <BooleanInput source="is_published" />
          <BooleanInput source="is_coach" label="코치" />
          <TextInput
            source="bio"
            multiline
            fullWidth
            style={{ width: "700px" }}
          />
          <NumberInput source="rank" />
          <SelectInput
            source="status"
            label="프로필 상태"
            choices={[
              { id: "A", name: "A" },
              { id: "B", name: "B" },
              { id: "C", name: "C" },
            ]}
          />
          <TextInput
            source="note"
            label="큐레이터 노트"
            multiline
            fullWidth
            style={{ width: "700px" }}
          />
        </SimpleForm>
      </Tab>
    </TabbedShowLayout>
  </Edit>
);

const QUERY_HOST_EMAILS = gql`
  query QueryHostEmails {
    profile(where: { is_published: { _eq: true } }) {
      user_id
      name
      user {
        id
        email
        email2
      }
    }
  }
`;
