import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  NumberField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  Edit,
  SimpleForm,
  EditButton,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
} from "react-admin";

const WantedFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by content" source="content" alwaysOn />
  </Filter>
);

export const WantedList = (props) => (
  <List filters={<WantedFilter />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />

      <ReferenceField
        label="작성자"
        source="user_id"
        reference="user"
        link="show"
      >
        <TextField source="display_name" />
      </ReferenceField>
      <FunctionField render={(record) => record.is_anonymous && "익명"} />
      <FunctionField
        label="내용"
        render={(record) => (
          <div style={{ maxWidth: "800px" }}>{record.content}</div>
        )}
      />
      <DateField source="created_at" label="작성시간" />
    </Datagrid>
  </List>
);

const WantedTitle = ({ record }) => {
  return <span>Wanted: {record ? `${record.name}` : ""}</span>;
};

const WantedShowActions = ({ data }) => (
  <TopToolbar>
    <ListButton record={data} />
    <EditButton record={data} />
  </TopToolbar>
);

export const WantedShow = (props) => (
  <Show title={<WantedTitle />} actions={<WantedShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <ReferenceField
          label="작성자"
          source="user_id"
          reference="user"
          link="show"
        >
          <TextField source="display_name" />
        </ReferenceField>
        <FunctionField
          render={(record) => record.is_anonymous && <strong>*익명*</strong>}
        />
        <FunctionField
          label="내용"
          render={(record) => (
            <div
              style={{ width: "600px" }}
              dangerouslySetInnerHTML={{
                __html:
                  record.content?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
              }}
            ></div>
          )}
        />
        <DateField label="Created At" source="created_at" />
        <ReferenceManyField label="댓글" reference="comment" target="wanted_id">
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="작성자"
              source="user_id"
              reference="user"
              link="show"
            >
              <TextField label="이름" source="display_name" />
            </ReferenceField>
            <FunctionField
              label=" 내용"
              render={(record) => (
                <>
                  <div
                    style={{ width: "600px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        record.content.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
                    }}
                  ></div>
                </>
              )}
            />
            <DateField label="작성시간" source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const WantedEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <NumberField source="id" />
      <TextField source="id" />
      <NumberField source="host_id" />
      <NumberField source="user_id" />
      <NumberField source="score" />
      <TextInput
        source="content"
        multiline
        fullWidth
        style={{ width: "700px" }}
      />
      <DateField label="생성날짜" source="created_at" />
      <DateField label="수정날짜" source="updated_at" />
    </SimpleForm>
  </Edit>
);
