const gatewayAddr = process.env.REACT_APP_GATEWAY_ADDR;
const zoomClientId = process.env.REACT_APP_ZOOM_CLIENT_ID;
const zoomRedirectURL = `${gatewayAddr}/zoom/auth/callback`;

export default function ZoomButton({ host_id, zoom_status }) {
  if (zoom_status !== "active") {
    return (
      <a
        href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${zoomClientId}&redirect_uri=${encodeURI(
          zoomRedirectURL
        )}&state=${host_id}`}
        rel="noopener noreferrer"
      >
        <img
          src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
          height="32"
          alt="줌 계정 연결하기"
        />
      </a>
    );
  }
  return null;
}
