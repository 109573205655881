import dayjs from "dayjs";
import { useState } from "react";
import { Button as RaButton, useCreate } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchProfile from "../profile/SearchProfile";
import { priceStr } from "../../util";

export default function AddGroupProduct({ refresh }) {
  const [open, setOpen] = useState(false);
  const [createProduct, { isLoading }] = useCreate(undefined, undefined, {
    onSuccess: (data, error) => {
      add그룹대화세션(data);
      refresh();
    },
  });
  const [createGroupMeeting] = useCreate();

  const [host, setHost] = useState();
  const [title, setTitle] = useState("중앙예닮학교 커리어 멘토링");
  const [duration, setDuration] = useState(40);
  const [startAt, setStartAt] = useState(dayjs("2023-06-09T00:00:00+09:00"));
  const [searchText, setSearchText] = useState("");
  const [hostName, setHostName] = useState("");

  const handleHostSelect = (selectedHost) => {
    setHost(selectedHost);
  };

  const add그룹대화세션 = async (data) => {
    const endAt = startAt.add(duration, "m");
    createGroupMeeting("group_meeting", {
      data: {
        product_id: data.id,
        host_id: host.id,
        status: "created",
        seq: 1,
        group_id: `${host.id}${startAt.unix()}`,
        start_at: startAt,
        end_at: endAt,
      },
    });
  };

  const add그룹대화상품 = async () => {
    await createProduct("product", {
      data: {
        title: title,
        user_id: host.id,
        duration: duration,
        type: "default",
        base_price: 0,
        pay_price: 0,
        min_guest_count: 2,
        max_guest_count: 50,
      },
    });

    await createProduct("organization_host", {
      data: {
        organization_id: 1,
        host_id: host.id,
      },
      meta: {},
    });

    setOpen(false);
  };

  return (
    <>
      <RaButton
        onClick={() => setOpen(true)}
        label="중앙예닮학교 커리어 멘토링 상품 추가"
      />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>중앙예닮학교 커리어 멘토링 상품 추가</DialogTitle>
        <DialogContent>
          <Stack flexDirection="row">
            <TextField
              label="호스트 이름"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setHostName(searchText);
                }
              }}
            />
            <Button onClick={() => setHostName(searchText)}>조회</Button>
          </Stack>
          {hostName && (
            <SearchProfile name={hostName} onSelect={handleHostSelect} />
          )}
          {host && (
            <p>
              선택한 호스트: {host.name}{" "}
              {(host.durations ?? [])
                .map(
                  (d) => `${priceStr(d.price_info.pay_price)}(${d.duration}분)`
                )
                .join(", ")}
            </p>
          )}
          <p>
            제목:{" "}
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </p>
          <p>
            소요시간(분):
            <br />
            <TextField
              number
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          </p>
          <p>
            시작시간:
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker value={startAt} onChange={(d) => setStartAt(d)} />
            </LocalizationProvider>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} disabled={isLoading}>
            Close
          </Button>
          <LoadingButton onClick={add그룹대화상품} loading={isLoading}>
            생성
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
