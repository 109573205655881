export default function LookerstudioPage() {
  return (
    <>
      <iframe
        title="Lookerstudio"
        width="1200"
        height="100%"
        src="https://lookerstudio.google.com/embed/reporting/9a4b9563-1750-4a22-88fb-fdada31945a8/page/p_vmej9hhq4c"
        frameborder="0"
        allowFullScreen
      ></iframe>
    </>
  );
}
