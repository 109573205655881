import { useState } from "react";
import {
  Button,
  Confirm,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListButton,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { apiAction } from "../apiAction";
import CreateTicketDialog from "../components/ticket/CreateTicket";

const TicketLisstActions = ({ data }) => {
  const refresh = useRefresh();
  return (
    <TopToolbar>
      <CreateTicketDialog refresh={refresh} />
    </TopToolbar>
  );
};

const filters = [<TextInput source="title" alwaysOn />];
const formatter = new Intl.NumberFormat("ko-KR", {
  style: "currency",
  currency: "KRW",
});
export const TicketList = (props) => (
  <List filters={filters} actions={<TicketLisstActions />} {...props}>
    <Datagrid bulkActionButtons={false} style={{ width: "1400px" }}>
      <TextField source="id" />
      <TextField source="no" />
      <TextField source="title" />
      <FunctionField
        label="할인방식"
        render={(record) => {
          if (record.point > 0) {
            return formatter.format(record.point) + "원 할인";
          }
          if (record.discount_type === "PERCENTAGE") {
            return record.discount_value + "% 할인";
          }
          return null;
        }}
      />
      <NumberField label="매수" source="max_usage_count" />
      <FunctionField
        label="정책"
        render={(record) => {
          if (!record.policy) return null;
          return (
            <>
              기간: {record.policy.period.start_at} ~{" "}
              {record.policy.period.end_at}
              <br />
              사용 가능 횟수: {record.policy.max_count_per_user}
              {record.policy.only?.host_id && (
                <>
                  <br />
                  호스트 ID: {record.policy.only.host_id}
                </>
              )}
            </>
          );
        }}
      />
      <ReferenceField
        label="호스트"
        source="host_id"
        reference="profile"
        link="show"
      >
        <TextField label="이름" source="name" />
      </ReferenceField>
      <TextField source="pay_method" />
      <FunctionField
        label="결제상태"
        render={(record) =>
          record.pay_method !== "transfer" && !(record.pay_id > 0) && "결제안함"
        }
      />
      <FunctionField render={(record) => record.refund_point > 0 && "환불"} />
      <TextField source="order_id" />
    </Datagrid>
  </List>
);

const TicketTitle = ({ record }) => {
  return <span>Ticket: {record ? `${record.name}` : ""}</span>;
};

const TicketShowActions = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [openRefund, setOpenRefund] = useState(false);
  const [loadingRefund, setLoadingRefund] = useState(false);

  const handleRefund = async () => {
    setLoadingRefund(true);
    const res = await apiAction("/ticket/refund", {
      no: record.no,
    });
    if (res.success === false) {
      alert(res.error.message);
    } else {
      refresh();
    }
    setLoadingRefund(false);
    setOpenRefund(false);
  };

  return (
    <TopToolbar>
      <Button
        disabled={record?.refund_id > 0}
        onClick={() => setOpenRefund(true)}
        label="강제환불"
      />
      <Confirm
        isOpen={openRefund}
        loading={loadingRefund}
        title="강제 환불"
        content="정말 환불 할꺼에요? 취소 못함!!"
        onConfirm={handleRefund}
        onClose={() => setOpenRefund(false)}
      />
      <ListButton record={record} label="Back" />
    </TopToolbar>
  );
};

export const TicketShow = (props) => (
  <Show title={<TicketTitle />} actions={<TicketShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="no" />
        <TextField source="title" />
        <TextField source="point" />
        <TextField source="pay_method" />
        <FunctionField
          render={(record) =>
            record.pay_method !== "transfer" &&
            !(record.pay_id > 0) &&
            "결제안함"
          }
        />
        <FunctionField render={(record) => record.refund_point > 0 && "환불"} />
        <ReferenceField
          label="호스트"
          source="host_id"
          reference="profile"
          link="show"
        >
          <TextField label="이름" source="name" />
        </ReferenceField>
        <TextField source="order_id" />
        <DateField source="created_at" showTime />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
