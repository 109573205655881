import { useState } from "react";
import {
  useCreate,
  useGetList,
  Button as RaButton,
  useRefresh,
} from "react-admin";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  Dialog,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";

function useProfileList({ name }) {
  const { data } = useGetList("profile", {
    pagination: { page: 1, perPage: 10 },
    sort: { field: "user_id", order: "ASC" },
    filter: {
      name: name,
      is_published: true,
    },
  });
  return data ?? [];
}

export default function AddHostDialog({ tag, onSuccess }) {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [name, setName] = useState("");
  const [searchText, setSearchText] = useState("");
  const profiles = useProfileList({ name });

  const handleProfileSelect = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedProfiles([...selectedProfiles, value]);
    } else {
      setSelectedProfiles(
        selectedProfiles.filter((profileId) => profileId !== value)
      );
    }
  };

  const [create, { loading }] = useCreate();

  const handleAdd = async () => {
    for (let i = 0; i < selectedProfiles.length; i++) {
      await create(
        "user_tag",
        {
          data: {
            tag_id: tag.id,
            user_id: selectedProfiles[i],
          },
        },
        { onSuccess: () => refresh() }
      );
    }
    setOpen(false);
    if (onSuccess) onSuccess();
  };

  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="호스트 추가" />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{ minHeight: "800px", maxHeight: "800px" }}
      >
        <DialogTitle style={{ display: "flex" }}>
          <TextField
            label="호스트 이름"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setName(searchText);
              }
            }}
          />
          <Button onClick={() => setName(searchText)}>조회</Button>
        </DialogTitle>
        <DialogContent>
          <List>
            {profiles.map((profile) => (
              <ListItem
                key={profile.id}
                secondaryAction={
                  <Checkbox
                    key={profile.id}
                    value={profile.id}
                    onChange={handleProfileSelect}
                    label={profile.name}
                  />
                }
              >
                <ListItemAvatar>
                  <Avatar src={profile.main_image} />
                </ListItemAvatar>
                <ListItemText>
                  {profile.name} <br />
                  {profile.title}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdd} disabled={loading}>
            {tag.name}에 호스트 추가하기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
