import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Button as RaButton } from "react-admin";
import { apiAction } from "../../apiAction";
import { isAdmin } from "../../lib/auth";
import { priceStr } from "../../util";
import SearchProfile from "../profile/SearchProfile";

export default function CreateTicketDialog({ refresh }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [host, setHost] = useState();
  const [title, setTitle] = useState();
  const [point, setPoint] = useState();
  const [searchText, setSearchText] = useState("");
  const [hostName, setHostName] = useState("");

  const handleHostSelect = (selectedHost) => {
    setHost(selectedHost);
    if ((selectedHost.durations ?? []).length > 0) {
      setPoint(selectedHost.durations[0].price_info.pay_price);
    } else {
      setPoint("");
    }
  };

  const createTicket = async () => {
    setLoading(true);
    const res = await apiAction("/ticket/create", {
      hostId: host?.id,
      title: title,
      point: parseInt(point),
    });
    console.log("create ticket: ", res);
    await refresh();
    setLoading(false);
    setOpen(false);
  };

  if (!isAdmin()) {
    return null;
  }

  return (
    <>
      <RaButton onClick={() => setOpen(true)} label="대화권 생성" />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>대화권 생성</DialogTitle>
        <DialogContent>
          <Stack flexDirection="row">
            <TextField
              label="호스트 이름"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setHostName(searchText);
                }
              }}
            />
            <Button onClick={() => setHostName(searchText)}>조회</Button>
          </Stack>
          {hostName && (
            <SearchProfile name={hostName} onSelect={handleHostSelect} />
          )}
          {host && (
            <p>
              선택한 호스트: {host.name}{" "}
              {(host.durations ?? [])
                .map(
                  (d) => `${priceStr(d.price_info.pay_price)}(${d.duration}분)`
                )
                .join(", ")}
            </p>
          )}
          <p>
            제목:{" "}
            <TextField
              style={{ width: "600px" }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </p>

          <p>
            금액:{" "}
            <TextField
              type="number"
              value={point}
              onChange={(e) => setPoint(e.target.value)}
            />
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} disabled={loading}>
            Close
          </Button>
          <LoadingButton onClick={createTicket} loading={loading}>
            생성
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
