import AssistantIcon from "@mui/icons-material/Assistant";
import GroupIcon from "@mui/icons-material/Group";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ViewListIcon from "@mui/icons-material/ViewList";
import buildHasuraProvider from "ra-data-hasura";
import { useEffect, useState } from "react";
import { Admin, CustomRoutes, Layout, Menu, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { apolloClient } from "./lib/apollo";
import { authProvider, isAdmin } from "./lib/auth";
import HiGptPage from "./pages/HiGptPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import LookerstudioPage from "./pages/LookerstudioPage";
import MeetingSchedulePage from "./pages/MeetingSchedulePage";
import SummaryPage from "./pages/SummaryPage";
import {
  MeetingList,
  MeetingShow,
  MessageList,
  OrderList,
  OrderShow,
  OrganizationEdit,
  OrganizationList,
  OrganizationShow,
  ProductEdit,
  ProductList,
  ProductShow,
  ProfileEdit,
  ProfileList,
  ProfileShow,
  ReviewEdit,
  ReviewList,
  ReviewShow,
  SaleList,
  SaleShow,
  SettingEdit,
  SettingList,
  SettingShow,
  TagCreate,
  TagEdit,
  TagList,
  TagShow,
  ThreadList,
  ThreadShow,
  TicketList,
  UserEdit,
  UserList,
  UserShow,
  UserTagList,
  WantedEdit,
  WantedList,
  WantedShow,
} from "./resource";
// import { OrderDataList } from "./resource/order_data";

export const MyMenu = (props) => {
  if (!isAdmin()) {
    return (
      <Menu {...props}>
        {/* <Menu.DashboardItem /> */}
        <Menu.Item
          to="/lookerstudio"
          primaryText="LookerStudio"
          leftIcon={<TrendingUpIcon />}
        />
        <Menu.Item
          to="/higpt"
          primaryText="HiGPT"
          leftIcon={<AssistantIcon />}
        />
        <Menu.Item
          to="/review"
          primaryText="후기"
          leftIcon={<ViewListIcon />}
        />
        <Menu.Item
          to="/meeting_status"
          primaryText="대화"
          leftIcon={<ViewListIcon />}
        />
        <Menu.Item
          to="/order_view"
          primaryText="결제내역"
          leftIcon={<ViewListIcon />}
        />
        <Menu.Item
          to="/meeting-schedule"
          primaryText="대화(캘린더)"
          leftIcon={<ViewListIcon />}
        />
        <Menu.Item
          to="/profile"
          primaryText="호스트"
          leftIcon={<ViewListIcon />}
        />
        <Menu.Item to="/tag" primaryText="태그" leftIcon={<ViewListIcon />} />
        <Menu.Item
          to="/ticket"
          primaryText="대화권"
          leftIcon={<ViewListIcon />}
        />
      </Menu>
    );
  }
  return (
    <Menu {...props}>
      {/* <Menu.DashboardItem /> */}
      <Menu.Item
        to="/summary"
        primaryText="통계"
        leftIcon={<TrendingUpIcon />}
      />
      <Menu.Item
        to="/lookerstudio"
        primaryText="LookerStudio"
        leftIcon={<TrendingUpIcon />}
      />
      <Menu.Item to="/higpt" primaryText="HiGPT" leftIcon={<AssistantIcon />} />
      <Menu.Item
        to="/open-meetings"
        primaryText="열린 미팅수"
        leftIcon={<GroupIcon />}
      />
      <Menu.Item to="/user" primaryText="사용자" leftIcon={<ViewListIcon />} />
      <Menu.Item
        to="/profile"
        primaryText="호스트"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/product"
        primaryText="코칭상품"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item to="/review" primaryText="후기" leftIcon={<ViewListIcon />} />
      <Menu.Item
        to="/meeting_status"
        primaryText="대화"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/meeting-schedule"
        primaryText="대화(캘린더)"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/order_view"
        primaryText="결제내역"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/ticket"
        primaryText="대화권"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/sale_record"
        primaryText="정산"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item to="/tag" primaryText="태그" leftIcon={<ViewListIcon />} />
      <Menu.Item
        to="/thread_view"
        primaryText="메세지 쓰레드"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/message"
        primaryText="메세지"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/wanted"
        primaryText="찾아주세요"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item to="/setting" primaryText="설정" leftIcon={<ViewListIcon />} />
      <Menu.Item
        to="/organization"
        primaryText="조직"
        leftIcon={<ViewListIcon />}
      />
      <Menu.Item
        to="/order_data"
        primaryText="order_data"
        leftIcon={<ViewListIcon />}
      />
    </Menu>
  );
};

export const MyLayout = (props) => <Layout {...props} menu={MyMenu} />;

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const buildDataProvider = async () => {
      const dataProvider = await buildHasuraProvider({ client: apolloClient });
      setDataProvider(() => dataProvider);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider) return <p>Loading...</p>;

  return (
    <Admin
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={MyLayout}
    >
      <CustomRoutes>
        <Route path="/summary" element={<SummaryPage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/lookerstudio" element={<LookerstudioPage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/higpt" element={<HiGptPage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/open-meetings" element={<HomePage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/meeting-schedule" element={<MeetingSchedulePage />} />
      </CustomRoutes>
      <Resource
        name="user"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        options={{ label: "사용자" }}
      />
      <Resource
        name="profile"
        list={ProfileList}
        show={ProfileShow}
        edit={ProfileEdit}
        options={{ label: "호스트" }}
      />
      <Resource
        name="product"
        list={ProductList}
        show={ProductShow}
        edit={ProductEdit}
        options={{ label: "코칭상품" }}
      />
      <Resource
        name="review"
        list={ReviewList}
        show={ReviewShow}
        edit={ReviewEdit}
      />
      <Resource name="meeting_status" list={MeetingList} show={MeetingShow} />
      <Resource name="order_view" list={OrderList} show={OrderShow} />
      <Resource name="ticket" list={TicketList} /* show={TicketShow} */ />
      <Resource name="sale_record" list={SaleList} show={SaleShow} />
      <Resource
        name="tag"
        list={TagList}
        show={TagShow}
        create={TagCreate}
        edit={TagEdit}
      />
      <Resource name="thread_view" list={ThreadList} show={ThreadShow} />
      <Resource name="message" list={MessageList} />
      <Resource
        name="wanted"
        list={WantedList}
        show={WantedShow}
        edit={WantedEdit}
      />
      <Resource
        name="user_tag_view"
        list={UserTagList}
        options={{ label: "호스트&태그" }}
      />
      <Resource
        name="setting"
        list={SettingList}
        show={SettingShow}
        edit={SettingEdit}
        options={{ label: "설정" }}
      />
      <Resource
        name="organization"
        list={OrganizationList}
        show={OrganizationShow}
        edit={OrganizationEdit}
      />
      {/* <Resource name="order_data" list={OrderDataList} /> */}
    </Admin>
  );
};

export default App;
