import { Login, LoginForm } from "react-admin";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import firebase from "firebase";

const uiConfig = {
  // signInFlow: "popup",
  signInSuccessUrl: "#/",
  // signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
};

const LoginPage = (props) => (
  <Login {...props}>
    <LoginForm {...props} />
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(getApp())} />
  </Login>
);

export default LoginPage;
