import { Button, IconButton, TextareaAutosize, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUpdate } from "react-admin";
import { useForm, useFieldArray } from "react-hook-form";
import { useRedirect } from "react-admin";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";

export default function CoachingInfoEdit({ profile }) {
  const redirect = useRedirect();

  const [update] = useUpdate();

  const {
    handleSubmit,
    control,
    register,
    formState: { isDirty },
  } = useForm({ defaultValues: { ...profile.coaching_info } });

  const onSubmit = async (data) => {
    await update("profile", {
      id: profile.id,
      data: { coaching_info: data },
      previousData: profile,
    });
    redirect(`/profile/${profile.id}/show/coaching`);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <ul style={{ width: "700px" }}>
          <li>
            color1: <StyledTextField label="color1" {...register("color1")} />
          </li>
          <li>
            color2:
            <StyledTextField label="color2" {...register("color2")} />
          </li>
          <li>
            color3:
            <StyledTextField label="color3" {...register("color3")} />
          </li>
          <li>
            name:
            <StyledTextField label="name" {...register("name")} />
          </li>
          <li>
            nickname:
            <StyledTextField label="nickname" {...register("nickname")} />
          </li>
          <li>
            job1:
            <StyledTextarea rows={2} abel="job1" {...register("job1")} />
          </li>
          <li>
            job2:
            <StyledTextField
              label="job2"
              {...register("job2")}
              fullWidth={true}
            />
          </li>
          <li>
            title:
            <StyledTextarea
              rows={2}
              label="title"
              {...register("title")}
              fullWidth={true}
            />
          </li>
          <li>
            subtitle:
            <StyledTextarea
              rows={2}
              label="subtitle"
              {...register("subtitle")}
              fullWidth={true}
            />
          </li>
          <li>
            introduction:
            <StyledTextarea
              rows={2}
              label="introduction"
              {...register("introduction")}
              fullWidth={true}
            />
          </li>
          <li>
            coaching_technique:
            <StyledTextField
              label="coaching_technique"
              {...register("coaching_technique")}
              fullWidth={true}
            />
          </li>
          <li>
            coaching_technique_desc1:
            <StyledTextField
              label="coaching_technique_desc1"
              {...register("coaching_technique_desc1")}
              fullWidth={true}
            />
          </li>
          <li>
            coaching_technique_desc2:
            <StyledTextarea
              rows={2}
              label="coaching_technique_desc2"
              {...register("coaching_technique_desc2")}
              fullWidth={true}
            />
          </li>
          <li>
            <BenefitForm control={control} register={register} />
          </li>
          <li>
            <PointsForm control={control} register={register} />
          </li>
          <li>
            <ContentsForm control={control} register={register} />
          </li>
          <li>
            커리큘럼:
            <ul>
              <li>
                사전단계:
                <StyledTextField
                  placeholder="설문 피드백 + 문제 정의"
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.pre_step.name`)}
                />
                <StyledTextarea
                  rows={2}
                  placeholder="나의 문제와 상황, 해결하고 싶은 부분을 작성합니다나의 문제와 상황, 해결하고 싶은 부분을 작성합니다"
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.pre_step.desc`)}
                />
                <StyledTextField
                  placeholder="ex) 1회차가 마칠 땐, 000에 대한 인사이트와 계획이 잡히게 됩니다."
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.pre_step.benefit`)}
                />
              </li>
            </ul>
            {/* <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton> */}
            <CurriculumForm control={control} register={register} />
            <ul>
              <li>
                후속단계:
                <StyledTextField
                  placeholder="설문 피드백 + 문제 정의"
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.post_step.name`)}
                />
                <StyledTextarea
                  rows={2}
                  placeholder="나의 문제와 상황, 해결하고 싶은 부분을 작성합니다나의 문제와 상황, 해결하고 싶은 부분을 작성합니다"
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.post_step.desc`)}
                />
                <StyledTextField
                  placeholder="ex) 1회차가 마칠 땐, 000에 대한 인사이트와 계획이 잡히게 됩니다."
                  variant="outlined"
                  hiddenLabel
                  sx={{ width: "700px" }}
                  {...register(`curriculum.post_step.benefit`)}
                />
              </li>
            </ul>
          </li>
          <li>
            회차/기간:
            <StyledTextarea
              rows={2}
              placeholder="2회/ 2-3주\n회차당 30분"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`schedule`)}
            />
          </li>
          <li>
            코칭 기간:
            <StyledTextField
              placeholder="2023년 1월 16일부터 ~ 3월 31일까지 종료"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`term`)}
            />
          </li>

          <li>
            offer.sup:
            <StyledTextField
              placeholder="선착순 4명 한정"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`offer.sup`)}
            />
          </li>
          <li>
            offer.title:
            <StyledTextField
              placeholder=" *40%* 특별 할인가"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`offer.title`)}
            />
          </li>
          <li>
            offer.p:
            <StyledTextarea
              placeholder="코칭대화 런칭 기념40% 할인 이벤트"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`offer.p`)}
            />
          </li>
        </ul>
        <input disabled={!isDirty} type="submit" />
      </form>
    </>
  );
}
function ContentsForm({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "contents",
  });

  const addItem = () => append({ value: "" });
  const removeItem = (i) => remove(i);

  return (
    <div style={{ margin: "3rem 0" }}>
      코칭 내용:
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <StyledTextField
              placeholder="ex) 실리콘 밸리에서 취업하기."
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`contents.${i}.value`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
    </div>
  );
}
function PointsForm({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "points",
  });

  const addItem = () => append({ value: "" });
  const removeItem = (i) => remove(i);

  return (
    <div style={{ margin: "3rem 0" }}>
      이런 분에게 필요해요:
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <StyledTextField
              placeholder="ex) 실리콘 밸리에서 취업하기."
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`points.${i}.value`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
    </div>
  );
}
function CurriculumForm({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "curriculum.steps",
  });

  const addItem = () => append({ value: "" });
  const removeItem = (i) => remove(i);

  return (
    <div style={{ margin: "3rem 0" }}>
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            {i + 1}회차
            <StyledTextField
              placeholder="설문 피드백 + 문제 정의"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`curriculum.steps.${i}.name`)}
            />
            <StyledTextarea
              rows={2}
              placeholder="나의 문제와 상황, 해결하고 싶은 부분을 작성합니다나의 문제와 상황, 해결하고 싶은 부분을 작성합니다"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`curriculum.steps.${i}.desc`)}
            />
            <StyledTextField
              placeholder="ex) 1회차가 마칠 땐, 000에 대한 인사이트와 계획이 잡히게 됩니다."
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`curriculum.steps.${i}.benefit`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
    </div>
  );
}

function BenefitForm({ control, register }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "benefits",
  });

  const addItem = () => append({ value: "" });
  const removeItem = (i) => remove(i);

  return (
    <div style={{ margin: "3rem 0" }}>
      왜 좋은가?
      <ul>
        {fields.map((p, i) => (
          <li key={p.id} style={{ marginTop: "1rem", marginBottom: "1rem" }}>
            {i + 1}:
            <StyledTextField
              placeholder="나도 모르는 나를 더욱 이해해요"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`benefits.${i}.title`)}
            />
            <StyledTextarea
              rows={2}
              placeholder="블라블라~~~"
              variant="outlined"
              hiddenLabel
              sx={{ width: "700px" }}
              {...register(`benefits.${i}.desc`)}
            />
            <IconButton onClick={() => removeItem(i)}>
              <CancelIcon />
            </IconButton>
          </li>
        ))}
      </ul>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<AddIcon />}
        sx={{ width: 180 }}
        onClick={addItem}
        style={{ marginLeft: "8rem", marginRight: "1rem" }}
      >
        추가
      </Button>
    </div>
  );
}

const StyledTextField = styled(TextField)`
  margin: 1rem;
`;
const StyledTextarea = styled(TextareaAutosize)`
  width: 700px;
  margin: 1rem;
  padding: 0.5rem;
`;
