import {
  List,
  Datagrid,
  TextInput,
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  useRecordContext,
  Edit,
  SimpleForm,
  BooleanInput,
  FunctionField,
  NumberInput,
  ReferenceField,
  ArrayInput,
  SimpleFormIterator,
  NumberField,
  FormDataConsumer,
  useUpdate,
  useRefresh,
  TopToolbar,
  ReferenceManyField,
  DateField,
  useDelete,
  ListButton,
  EditButton,
  SingleFieldList,
} from "react-admin";
import { useParams } from "react-router-dom";
import AddGroupProduct from "../components/product/AddGroupProduct";

const ProductListActions = ({ data }) => {
  const refresh = useRefresh();
  return (
    <TopToolbar>
      <AddGroupProduct refresh={refresh} />
    </TopToolbar>
  );
};

export const ProductList = (props) => (
  <List actions={<ProductListActions />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <ReferenceField
        label="호스트"
        source="user_id"
        reference="profile"
        link="show"
      >
        <TextField label="이름" source="name" />
      </ReferenceField>
      <NumberField label="노출순서" source="seq" />
      <BooleanField label="노출 여부" source="enable" />
      <BooleanField label="판매가능 여부" source="available" />
      <FunctionField
        label="인원수"
        render={(record) =>
          record.max_guest_count !== 1
            ? `${record.min_guest_count} ~ ${record.max_guest_count}`
            : 1
        }
      />
      <TextField source="title" label="제목" />
      <FunctionField
        label="세션"
        render={(record) => (
          <div style={{ minWidth: "150px", maxWidth: "330px" }}>
            <ReferenceManyField
              label="세션"
              reference="group_meeting"
              target="product_id"
            >
              <SingleFieldList>
                <div>
                  <DateField source="start_at" showTime />
                  <span
                    style={{
                      margin: "10px",
                    }}
                  />
                </div>
              </SingleFieldList>
            </ReferenceManyField>
          </div>
        )}
      />
      <NumberField
        source="pay_price"
        label="최종 결제 가격"
        locales="ko-KR"
        options={{ style: "currency", currency: "KRW" }}
      />
    </Datagrid>
  </List>
);

const ProductTitle = () => {
  const record = useRecordContext();
  return <span>Product: {record?.name}</span>;
};

const ShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <ListButton record={record} />
      <EditButton record={record} />
    </TopToolbar>
  );
};

export const ProductShow = (props) => {
  const refresh = useRefresh();
  const [deleteOne] = useDelete();
  return (
    <Show title={<ProductTitle />} actions={<ShowActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <ReferenceField
            label="호스트"
            source="user_id"
            reference="profile"
            link="show"
          >
            <TextField label="이름" source="name" />
          </ReferenceField>
          <NumberField label="노출순서" source="seq" />
          <BooleanField label="노출 여부" source="enable" />
          <BooleanField label="판매가능 여부" source="available" />
          <FunctionField
            label="인원수"
            render={(record) =>
              record.max_guest_count !== 1
                ? `${record.min_guest_count} ~ ${record.max_guest_count}`
                : 1
            }
          />
          <TextField source="title" label="제목" />
          <TextField source="subtitle" label="부제목" />
          <NumberField
            source="pay_price"
            label="최종 결제 가격"
            locales="ko-KR"
            options={{ style: "currency", currency: "KRW" }}
          />
          <TextField source="term" label="일시" />
          <TextField source="schedule" label="시간대" />
          <NumberField source="duration" label="1회 대화 시간(분)" />
          {/* <TextField source="schedule_from" label="시간 선택 방식" /> */}
          <FunctionField
            label="설명"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    record.description?.replace(/(?:\r\n|\r|\n)/g, "<br>") ??
                    "",
                }}
              ></div>
            )}
          />
          <FunctionField
            label="단계"
            render={(record) => (
              <ol>
                {record.steps?.map((step, i) => (
                  <li key={i}>
                    <p>{step.name}</p>
                    <p>{step.desc}</p>
                    <ul>
                      {step.pre_step && (
                        <li style={{ display: "flex" }}>
                          <span>사전 과정: &nbsp;</span>
                          <div>
                            <strong>{step.pre_step.name}</strong>
                            <br />
                            {step.pre_step.desc}
                          </div>
                        </li>
                      )}
                      {step.post_step && (
                        <li style={{ display: "flex" }}>
                          <span>사후 과정: &nbsp;</span>
                          <div>
                            <strong>{step.post_step.name}</strong>
                            <br />
                            {step.post_step.desc}
                          </div>
                        </li>
                      )}
                    </ul>
                  </li>
                ))}
              </ol>
            )}
          />
          <FunctionField
            label="혜택"
            render={(record) => (
              <div
                style={{ width: "600px" }}
                dangerouslySetInnerHTML={{
                  __html:
                    record.benefit?.replace(/(?:\r\n|\r|\n)/g, "<br>") ?? "",
                }}
              ></div>
            )}
          />
          <ReferenceManyField
            label="세션"
            reference="group_meeting"
            target="product_id"
          >
            <Datagrid bulkActionButtons={false}>
              <DateField source="start_at" showTime />
              <DateField source="end_at" showTime />
              <FunctionField
                label="삭제"
                render={(record) => (
                  <button
                    onClick={() =>
                      deleteOne(
                        "group_meeting",
                        { id: record.id },
                        { onSuccess: () => refresh() }
                      )
                    }
                  >
                    삭제
                  </button>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ProductEdit = (props) => {
  const record = useRecordContext();

  const { id } = useParams();
  const [update] = useUpdate("product", { id: id });
  return (
    <Edit {...props}>
      <SimpleForm
        onSubmit={(data) => {
          if (!data) return;

          for (let i = 0; i < (data.steps ?? []).length; i++) {
            const step = data.steps[i];
            if (
              (step?.pre_step?.name ?? "").length === 0 &&
              (step?.pre_step?.desc ?? "").length === 0
            ) {
              delete step.pre_step;
            }

            if (
              (step?.post_step?.name ?? "").length === 0 &&
              (step?.post_step?.desc ?? "").length === 0
            ) {
              delete step.post_step;
            }
          }

          update("product", {
            id: id,
            data: data,
            previousData: record,
          });
        }}
      >
        <TextField source="id" />
        <BooleanInput source="enable" label="노출 여부" />
        <BooleanInput source="available" label="판매가능 여부" />
        <NumberInput source="seq" label="노출순서" />
        <NumberInput source="min_guest_count" label="최소 신청 인원" />
        <NumberInput source="max_guest_count" label="최대 신청 인원" />
        <TextInput source="title" label="제목" style={{ width: "700px" }} />
        <TextInput
          source="subtitle"
          label="부제목"
          style={{ width: "700px" }}
        />
        <NumberInput source="pay_price" label="최종 결제 가격" />
        <TextInput source="term" label="일시" style={{ width: "700px" }} />
        <TextInput source="schedule" label="시간대" />
        <NumberInput source="duration" label="1회 대화 시간(분)" />
        {/* <TextInput source="schedule_from" label="시간 선태 방식" /> */}
        <TextInput
          source="description"
          label="설명"
          multiline
          fullWidth
          style={{ width: "700px" }}
        />
        <FunctionField
          label="단계"
          render={(record) => {
            if (!record.steps) return <></>;
            return (
              <ArrayInput source="steps" label="단계">
                <SimpleFormIterator inline>
                  <TextInput
                    label="이름"
                    source="name"
                    helperText={false}
                    multiline
                    fullWidth
                    style={{ width: "580px" }}
                  />
                  <TextInput
                    label="내용"
                    source="desc"
                    helperText={false}
                    multiline
                    fullWidth
                    style={{ width: "580px" }}
                  />
                  <FormDataConsumer>
                    {({ scopedFormData, getSource }) => (
                      <>
                        <div style={{ display: "flex" }}>
                          <span>사전과정:&nbsp;</span>
                          <div>
                            <TextInput
                              label="사전과정 이름"
                              source={getSource("pre_step.name")}
                              helperText={false}
                              fullWidth
                              style={{ width: "300px" }}
                            />
                            <br />
                            <TextInput
                              label="내용"
                              source={getSource("pre_step.desc")}
                              multiline
                              fullWidth
                              style={{ width: "520px" }}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          <span>사후과정:&nbsp;</span>
                          <div>
                            <TextInput
                              label="사후과정 이름"
                              source={getSource("post_step.name")}
                              helperText={false}
                              fullWidth
                              style={{ width: "300px" }}
                            />
                            <br />
                            <TextInput
                              label="내용"
                              source={getSource("post_step.desc")}
                              multiline
                              fullWidth
                              style={{ width: "520px" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        />

        <TextInput
          source="benefit"
          label="혜택"
          multiline
          fullWidth
          style={{ width: "700px" }}
        />
      </SimpleForm>
    </Edit>
  );
};
