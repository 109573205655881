import React, { useState, useCallback, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, IconButton, Dialog, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
//@ts-ignore
import ImageBlobReduce from "image-blob-reduce";
//@ts-ignore
import Pica from "pica";

const pica = Pica({ features: ["js", "wasm", "cib"] });
const reduce = new ImageBlobReduce({ pica });

export default function ImageEditBox({ file, uploadFile, processing }) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 3 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      const reducedBlob = await reduce.toBlob(e.target.files[0], { max: 1000 });
      reader.readAsDataURL(reducedBlob);
    }
  };

  useEffect(() => {
    if (file === undefined) {
      setUpImg(undefined);
    } else {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(file);
    }
  }, [file]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const uploadBox = (
    <UploadBox>
      <label htmlFor="icon-button-image-file">
        <Input
          accept="image/*"
          id="icon-button-image-file"
          type="file"
          onChange={onSelectFile}
          disabled={processing}
        />
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          disabled={processing}
        >
          <AddPhotoAlternateIcon />
        </IconButton>
        <center>사진</center>
      </label>
    </UploadBox>
  );

  if (upImg === undefined) return <>{uploadBox}</>;

  return (
    <Dialog open={upImg !== undefined}>
      <StyledDialogContent>
        <StyledIconButton onClick={() => setUpImg(undefined)}>
          <CloseIcon />
        </StyledIconButton>
        <div className="preview">
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              float: "right",
              width: 42,
              height: 56,
            }}
          />
        </div>
        <h2>3:4 비율에 맞게 이미지를 조정해주세요</h2>

        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />

        <div className="btnWrap">
          <LoadingButton
            variant="outlined"
            // color="default"
            sx={{ width: "50%" }}
            startIcon={<PublishIcon />}
            loading={processing}
            loadingPosition="start"
            disabled={!completedCrop?.width || !completedCrop?.height}
            onClick={() => {
              if (!previewCanvasRef.current || !completedCrop) {
                return;
              }

              previewCanvasRef.current.toBlob(async (blob) => {
                const reducedBlob = await reduce.toBlob(blob, { max: 1000 });
                await uploadFile(
                  new File([reducedBlob], "crop.png", { type: blob.type })
                );
                setUpImg(undefined);
              });
            }}
          >
            업로드
          </LoadingButton>
        </div>
      </StyledDialogContent>
    </Dialog>
  );
}

const Input = styled("input")({
  display: "none",
});

const UploadBox = styled(Box)`
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 5rem;
    height: 5rem;
  }
`;
const StyledDialogContent = styled(DialogContent)`
  h2 {
    font-weight: 600;
    margin: 2rem 0;
  }
  .preview {
    // display: none;
  }
  .btnWrap {
    margin: 2rem;
    display: flex;
    justify-content: center;
    button {
      font-weight: 600;
      font-family: "Pretendard-Regular";
    }
  }
  img.ReactCrop__image {
    max-height: 450px;
  }
  .preview {
    display: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  svg {
    width: 3rem;
    height: 3rem;
  }
`;
