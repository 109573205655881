import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  ListButton,
  ReferenceField,
  FunctionField,
  ReferenceManyField,
  Link,
  useRecordContext,
  useRefresh,
  TextInput,
} from "react-admin";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SendMessageDialog from "../components/message/SendMessageDialog";
import dayjs from "dayjs";

const filters = [
  <TextInput label="호스트 이름으로 검색" source="host_name" alwaysOn />,
  <TextInput label="게스트 이름으로 검색" source="guest_name" alwaysOn />,
];

export const ThreadList = (props) => (
  <List filters={filters} {...props} style={{ width: "1100px" }}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />

      <ReferenceField
        label="호스트"
        source="host_id"
        reference="profile"
        link="show"
      >
        <TextField label="이름" source="name" />
      </ReferenceField>
      <ReferenceField
        label="게스트"
        source="guest_id"
        reference="user"
        link="show"
      >
        <TextField label="이름" source="display_name" />
      </ReferenceField>
      <ReferenceField
        label="최근 메세지"
        source="last_message_id"
        reference="message"
      >
        <FunctionField
          render={(record) => (
            <div style={{ width: "600px" }}>
              <Link to={`/thread_view/${record.thread_id}/show`}>
                {record.content.body}
              </Link>
            </div>
          )}
        />
      </ReferenceField>
      <DateField source="updated_at" label="최근 메세지" />
    </Datagrid>
  </List>
);

const ThreadTitle = ({ record }) => {
  return <span>Thread: {record ? `${record.name}` : ""}</span>;
};

const ThreadShowActions = ({ data }) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  return (
    <TopToolbar>
      <SendMessageDialog thread={record} onSuccess={refresh} />
      <ListButton record={data} />
    </TopToolbar>
  );
};

export const ThreadShow = (props) => (
  <Show title={<ThreadTitle />} actions={<ThreadShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <ReferenceField
          label="호스트"
          source="host_id"
          reference="profile"
          link="show"
        >
          <TextField label="이름" source="name" />
        </ReferenceField>
        <ReferenceField
          label="게스트"
          source="guest_id"
          reference="user"
          link="show"
        >
          <TextField label="이름" source="display_name" />
        </ReferenceField>
        <ReferenceManyField
          label="메세지"
          reference="message"
          target="thread_id"
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceField
              label="작성자"
              source="user_id"
              reference="user"
              link="show"
            >
              <TextField label="이름" source="display_name" />
            </ReferenceField>
            <FunctionField
              label="메세지 내용"
              render={(record) => (
                <>
                  {record.content_type === "event" && <NotificationsNoneIcon />}
                  <div
                    style={{ width: "600px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        record.content.body.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br>"
                        ) ?? "",
                    }}
                  ></div>
                  {record.content_type === "reschedule" &&
                    dayjs(record.content.info?.start_at).format(
                      "(제안시간: M/D HH:ss)"
                    )}
                </>
              )}
            />
            <DateField label="작성시간" source="created_at" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
