import { accessToken } from "./lib/auth";

export async function apiAction(path, body) {
  const token = await accessToken();

  const res = await fetch(`${process.env.REACT_APP_GATEWAY_ADDR}${path}`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { Authorization: `Bearer ${token}` },
  });
  return res.json();
}
