import {
  List,
  Datagrid,
  TextInput,
  TextField,
  DateField,
  Show,
  TabbedShowLayout,
  Tab,
  BooleanField,
  useRecordContext,
  Edit,
  SimpleForm,
  DateInput,
  BooleanInput,
} from "react-admin";

export const SettingList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" label="이름" />
      <BooleanField source="enable" />
      <DateField source="start_at" label="시작" showTime />
      <DateField source="end_at" label="종료" showTime />
      <TextField source="info" label="내용" />
    </Datagrid>
  </List>
);

const SettingTitle = () => {
  const record = useRecordContext();
  return <span>Setting: {record?.name}</span>;
};

export const SettingShow = (props) => (
  <Show title={<SettingTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" label="이름" />
        <BooleanField source="enable" />
        <DateField source="start_at" label="시작" showTime />
        <DateField source="end_at" label="종료" showTime />
        <TextField source="info" label="내용" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const SettingEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" label="이름" />
      <BooleanInput source="enable" />
      <DateInput source="start_at" label="시작" />
      <DateInput source="end_at" label="종료" />
      <TextField source="info" label="내용" />
    </SimpleForm>
  </Edit>
);
