import {
  List,
  Filter,
  Datagrid,
  TextInput,
  TextField,
  DateField,
} from "react-admin";

const UserTagFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search by tag_name" source="tag_name" alwaysOn />
  </Filter>
);

export const UserTagList = (props) => (
  <List filters={<UserTagFilter />} {...props}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="profile_name" />
      <TextField source="tag_name" />
      <DateField source="created_at" label="Created At" />
    </Datagrid>
  </List>
);
