import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  FormControl,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

export const ChattingBox = ({ meetingId }) => {
  const [dialog, setDialog] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const defaultMessage =
    "너는 helpful assistant야, 내가 앞으로 2명이서 한 대화내용의 일부분을 줄 거고 너는 그 내용들을 바탕으로 내가 하는 질문에 대한 적절한 답변을 한글로 해줘, 대화내용 : ~~대화~~";
  const [systemMessage, setSystemMessage] = useState(defaultMessage);
  const [modalValue, setModalValue] = useState(defaultMessage);

  useEffect(() => {
    const storedMessage = localStorage.getItem("GPTsystem");
    if (storedMessage) {
      setSystemMessage(storedMessage);
      setModalValue(storedMessage);
    }
  }, []);

  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      const { scrollHeight, clientHeight } = chatBoxRef.current;
      chatBoxRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [dialog]);

  async function postDialog() {
    const res = await fetch("https://dev.hithere.co/rag-request/ask", {
      // const res = await fetch("http://localhost:3030/ask", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text: message,
        meeting_id: meetingId,
        system_message: systemMessage,
      }),
    });
    const reader = res.body
      // eslint-disable-next-line no-undef
      .pipeThrough(new TextDecoderStream())
      .getReader();

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        setLoading(false);
        break;
      }

      setDialog((dialog) => {
        if (dialog[dialog.length - 1].role === "user") {
          return [...dialog, { role: "bot", content: value }];
        }
        return [
          ...dialog.slice(0, -1),
          { role: "bot", content: dialog[dialog.length - 1].content + value },
        ];
      });
    }

    setMessage("");
  }

  async function saveDialog(index) {
    setLoading(true);
    const res = await fetch("https://dev.hithere.co/rag-request/save", {
      // const res = await fetch("http://localhost:3030/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        meeting_id: meetingId,
        system_message: dialog[index - 1].system_message,
        question: dialog[index - 1].content,
        answer: dialog[index].content,
      }),
    });

    if (res.ok) {
      setLoading(false);
    } else {
      setLoading(false);
      alert("저장에 실패했습니다.");
    }
  }

  const onSubmit = () => {
    if (message === "") return;
    setLoading(true);
    setDialog((dialog) => [
      ...dialog,
      { role: "user", content: message, system_message: systemMessage },
    ]);
    postDialog();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!loading) onSubmit();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        alignItems: "center",
        justifyContent: "space-around",
        width: "70%",
        minWidth: "320px",
        minHeight: "70vh",
      }}
    >
      <Paper
        ref={chatBoxRef}
        sx={{
          width: "100%",
          flexGrow: 1,
          overflowY: "auto",
          maxHeight: "70vh",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {dialog
            ? dialog.map((message, index) => {
                /*if (index === 0) return null;*/
                const isUser = message.role === "user";
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: isUser ? "flex-end" : "flex-start",
                      padding: "6px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "70%",
                        backgroundColor: isUser ? "#CFEAF8" : "#F9CCCC",
                        padding: "1em",
                        borderRadius: "1em",
                      }}
                    >
                      {message.content}
                    </Box>
                    {!isUser && (
                      <IconButton onClick={() => saveDialog(index)}>
                        <SaveAltIcon />
                      </IconButton>
                    )}
                  </Box>
                );
              })
            : ""}
        </Box>
        <IconButton
          onClick={() => setOpen(true)}
          sx={{
            position: "sticky",
            bottom: 0,
            alignSelf: "flex-end",
            zIndex: 2,
          }}
        >
          <SettingsIcon />
        </IconButton>
      </Paper>

      <FormControl
        component="form"
        sx={{
          width: "100%",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <TextField
          label="Chat GPT에게 질문"
          multiline
          value={message}
          onInput={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : <SendIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: "12px",
            ".MuiInputBase-root": {
              display: "flex",
            },
            "&:focus": {
              outline: "none",
              borderColor: "blue",
              boxShadow: "0 0 0 3px rgba(0, 0, 255, 0.6)",
            },
          }}
        />
      </FormControl>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>시스템 메세지 설정</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: "1em" }}>
            `~~대화~~` 대신 실제 대화 내용이 들어갑니다
          </div>
          <TextField
            multiline
            value={modalValue}
            onInput={(e) => setModalValue(e.target.value)}
            sx={{ maxWidth: "500px", width: "85vw" }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setModalValue(defaultMessage);
            }}
          >
            초기화
          </Button>
          <Button
            disabled={modalValue === systemMessage}
            onClick={() => {
              if (!modalValue.includes("~~대화~~")) {
                alert(`"~~대화~~"가 들어가야 합니다`);
                return;
              }
              setSystemMessage(modalValue);
              localStorage.setItem("GPTsystem", modalValue);
            }}
          >
            저장
          </Button>
          <Button onClick={() => setOpen(false)}>닫기</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
