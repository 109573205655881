import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Button as RaButton, useRefresh } from "react-admin";
import { priceStr } from "../../util";
import { gql } from "@apollo/client";
import { apolloClient } from "../../lib/apollo";
import { LoadingButton } from "@mui/lab";
import { apiAction } from "../../apiAction";

export default function ClearanceDialog({ saleRecord }) {
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [discountRate, setDiscountRate] = useState();
  const [note, setNote] = useState(saleRecord.note);
  const [priceInfo, setPriceInfo] = useState();
  const [realHostTax1Amount, setRealHostTax1Amount] = useState(
    saleRecord.real_host_tax_1_amount ?? saleRecord.host_tax_1_amount
  );
  const [realHostTax2Amount, setRealHostTax2Amount] = useState(
    saleRecord.real_host_tax_2_amount ?? saleRecord.host_tax_2_amount
  );
  const [realHostSettlementAmount, setRealHostSettlementAmount] = useState(
    saleRecord.real_host_settlement_amount ?? saleRecord.host_settlement_amount
  );
  const [settlementDate, setSettlementDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  useEffect(() => {
    // setNote(discountRate > 0 ? `할인 ${discountRate}%` : "");
    setPriceInfo(undefined);
    if (discountRate === undefined) return;
    if (discountRate < 0) return;
    // if (`${payPrice}` === `${priceInfo?.pay_price}`) return;
    const timeOutId = setTimeout(
      () =>
        apiAction("/sale/calculate", {
          base_price: saleRecord.base_price,
          host_fee_rate: saleRecord.host_fee_rate,
          discount_rate: discountRate / 100,
        }).then(({ data }) => setPriceInfo(data)),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [discountRate, saleRecord.base_price, saleRecord.host_fee_rate]);

  useEffect(() => {
    if (!priceInfo) return;
    if (priceInfo.host_base_price <= 30000) {
      setRealHostTax1Amount(0);
      setRealHostTax2Amount(0);
      setRealHostSettlementAmount(priceInfo.host_base_price);
    } else {
      setRealHostTax1Amount(priceInfo.host_tax_1_amount);
      setRealHostTax2Amount(priceInfo.host_tax_2_amount);
      setRealHostSettlementAmount(
        priceInfo.host_base_price - priceInfo.host_tax_amount
      );
    }
  }, [priceInfo]);

  const submit = async () => {
    setLoading(true);
    await apolloClient.mutate({
      mutation: CLEARANCE_SALE_RECORD_AND_ORDER,
      variables: {
        order_id: saleRecord.id,
        real_host_tax_1_amount: realHostTax1Amount,
        real_host_tax_2_amount: realHostTax2Amount,
        real_host_settlement_amount: realHostSettlementAmount,
        settlement_date: settlementDate,
        note: (note ?? "").length > 0 ? note : null,
      },
      update: () => {
        setOpen(false);
        refresh();
      },
    });
    setLoading(false);
  };

  return (
    <>
      <RaButton
        onClick={async (e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <>정산하기</>
      </RaButton>
      <Dialog open={open}>
        <DialogTitle>
          {saleRecord.host_name}
          <IconButton
            aria-label="close"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <StyledUL>
            <li>
              <div className="name">판매가격: </div>
              <div className="price">{priceStr(saleRecord.pay_price)}</div>
            </li>
            <li>
              <div className="name">기본가격: </div>
              <div className="price">{priceStr(saleRecord.base_price)}</div>
            </li>
            <li>
              <div className="name">호스트 수익: </div>
              <div className="price">
                {priceStr(saleRecord.host_base_price)}
              </div>
              (호스트 수수료: {Math.floor(saleRecord.host_fee_rate * 100)}%)
            </li>
            <li>
              <div className="name">할인(%): </div>
              <StyledTextField
                type="number"
                value={discountRate}
                onChange={(e) => setDiscountRate(e.target.valueAsNumber)}
              />
              {discountRate > 0 && (
                <>(실제수익: {priceInfo?.host_base_price})</>
              )}
            </li>
            <li>
              <div className="name">원천징수세액:</div>
              <StyledTextField
                type="number"
                value={realHostTax1Amount}
                onChange={(e) => setRealHostTax1Amount(e.target.valueAsNumber)}
              />
              (소득세)
            </li>
            <li>
              <div className="name"></div>
              <StyledTextField
                type="number"
                value={realHostTax2Amount}
                onChange={(e) => setRealHostTax2Amount(e.target.valueAsNumber)}
              />
              (지방소득세)
            </li>
            <li>
              <div className="name">호스트 지급액: </div>
              <StyledTextField
                type="number"
                value={realHostSettlementAmount}
                onChange={(e) =>
                  setRealHostSettlementAmount(e.target.valueAsNumber)
                }
              />
            </li>
            <li>
              <div className="name">정산 날짜: </div>
              <StyledTextField
                value={settlementDate}
                onChange={(e) => setSettlementDate(e.target.value)}
              />
            </li>
            <li>
              <div className="name">노트: </div>
              <StyledTextField
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ width: "200px" }}
                inputProps={{ style: { textAlign: "left" } }}
              />
            </li>
          </StyledUL>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
            }}
          >
            취소
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() => submit()}
          >
            정산 완료
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

const StyledUL = styled("ul")`
  li {
    display: flex;
    margin: 5px 0;
    .name {
      width: 110px;
      text-align: right;
    }
    .price {
      width: 80px;
      text-align: right;
      margin-right: 3px;
    }
  }
`;
const StyledTextField = styled(TextField)`
  width: 90px;
  input {
    margin: 1px;
    padding: 1px;
    text-align: right;
  }
`;

const CLEARANCE_SALE_RECORD_AND_ORDER = gql`
  mutation ClearanceSaleRecordAndOrder(
    $order_id: bigint!
    $real_host_settlement_amount: Int!
    $real_host_tax_1_amount: Int!
    $real_host_tax_2_amount: Int!
    $settlement_date: date!
    $note: String!
  ) {
    update_sale_record_by_pk(
      pk_columns: { id: $order_id }
      _set: {
        real_host_settlement_amount: $real_host_settlement_amount
        real_host_tax_1_amount: $real_host_tax_1_amount
        real_host_tax_2_amount: $real_host_tax_2_amount
        settlement_date: $settlement_date
        note: $note
      }
    ) {
      id
    }
    update_order_by_pk(
      pk_columns: { id: $order_id }
      _set: { status: "closed" }
    ) {
      id
    }
  }
`;
