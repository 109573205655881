import { useGetList } from "react-admin";
import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { priceStr } from "../../util";

const SearchProfile = ({ name, onSelect }) => {
  const { data, loading } = useGetList("profile", {
    pagination: { page: 1, perPage: 5 },
    sort: { field: "user_id", order: "ASC" },
    filter: {
      name: name,
      is_published: true,
    },
  });

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <List>
          {data?.map((profile) => (
            <ListItem key={profile.id}>
              <ListItemButton onClick={() => onSelect(profile)}>
                <ListItemAvatar>
                  <Avatar src={profile.main_image} />
                </ListItemAvatar>
                <ListItemText>
                  {profile.name}{" "}
                  {(profile.durations ?? [])
                    .map(
                      (d) =>
                        `${priceStr(d.price_info.pay_price)}(${d.duration}분)`
                    )
                    .join(", ")}{" "}
                  <br />
                  {profile.title}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

export default SearchProfile;
